import './scss/Panel.scss'
import '@aws-amplify/ui-react/styles.css';
import { UserContextProvider } from './context/UserContext'
import Login from './Login'
import {Routes, Route, Outlet} from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import LoginRoute from './components/LoginRoute'
import NotFound from './404'
import Header from './components/Header'
import LeftNav from './components/LeftNav'
import Dashboards from './components/Body/Dashboards'
import FleetExecutions from './components/Body/FleetExecutions'
import WeeklyRefresh from './components/Body/WeeklyRefresh'
import ClientJobSizes from './components/Body/ClientJobSizes'
import { useSelector } from 'react-redux/es/hooks/useSelector';

function App():JSX.Element {
  const configSize = useSelector((state: any) => state.config.size)

  return (
    <UserContextProvider>
      <Routes>
        <Route
          path="/login"
          element={
            <LoginRoute>
              <Login />
            </LoginRoute>
          }
        />
          <Route
            path="/"
            element={
              <PrivateRoute>
                  <>
                    <Header />
                    <main className={configSize === 'bigMain'? 'bigMain': 'smallMain'}>
                        <LeftNav />
                        <Outlet />
                    </main>
                  </>
              </PrivateRoute>
            }
          >
            <Route path='' element={<FleetExecutions />} />
            <Route path='dashboard-updates'
                   element={<h1 style={{margin: '0', paddingTop: '2rem', paddingLeft: '3rem', width: '100vw', height: '100vh', backgroundColor: '#faf8f4'}}>Under Construction</h1>}
                   />
            <Route path='notifications'
                   element={<h1 style={{margin: '0', paddingTop: '2rem', paddingLeft: '3rem', width: '100vw', height: '100vh', backgroundColor: '#faf8f4'}}>Under Construction</h1>}
                   />
            <Route path='FleetExecutions' element={<FleetExecutions/>} />
            <Route path="fleet_execution/:fleet_execution_id" element={<FleetExecutions />} />
            <Route path="dashboard/:dashboard_id" element={<Dashboards />} />
            <Route path="WeeklyRefresh" element={<WeeklyRefresh />} />
            <Route path="JobSizes" element={<ClientJobSizes />} />
            <Route path="JobSizes/:codename" element={<ClientJobSizes />} />
          </Route>
          
          <Route 
            path='*' 
            element={<NotFound />} />
      </Routes>
    </UserContextProvider>
  );
}

export default App;
