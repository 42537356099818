import { useParams } from "react-router-dom";

import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import fetchAccessToken from '../../utils/fetchAccessToken';

import { Buffer } from 'buffer'

// Render an IFrame from a url
function iframe(path: string) {
    return {
        __html: '<iframe src="' + path + '" width="1400" height="900" sandbox = "allow-scripts"></iframe>'
    }
}

function iframe_srcdoc(html: string) {
    return {
        __html: '<iframe srcdoc="' + html + '" width="1400" height="900" sandbox = "allow-scripts"></iframe>'
    }
}


// Decode base64 to string
// Thanks, Stack: https://stackoverflow.com/a/61155795
const decode = (str: string):string => Buffer.from(str, 'base64').toString('binary');

// Encode string to base64
// Thanks, Stack: https://stackoverflow.com/a/61155795
const encode = (str: string):string => Buffer.from(str, 'binary').toString('base64');

// Convert base64 dashboard to string (handle nulls)
function unwrapDashboardContents (input: string|null) {
    if (input == null) {
        return("")
    } else {
        // Safety: We're handling nulls already, so these are safe escapes.
        return(htmlDecode(input)!)
    }

}

// Escape html strings
function htmlDecode(input: string) {
    console.log("Htmldecode:")
    console.log(input)
    let html : string
    if (input == null) {
        html = ""
    } else {
        html = input
    }

    const entityMap = new Map<string, string>(Object.entries({
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': '&quot;',
    "'": '&#39;',
    "/": '&#x2F;'
}))

    return String(html).replace(/[&<>"'/]/g, (s: string) => entityMap.get(s)!);
}


// This hits the OpMan API to acquire a presigned s3 url of the dashboard under
// `response.data.presigned_url`
const getDashboard = async(dashboard_id: string) => {
    const token = await fetchAccessToken();
    console.log("Pinging API")
    return axios.get(`${process.env.REACT_APP_URL}/v2/user/dashboard/${dashboard_id}?contents=True`,{
          headers: {
             'Content-Type': 'application/json',
             'Authorization': `Bearer ${token}`
          },
          timeout: 60000
     }
  )
}

const readPresignedUrl = async(url: string, token: string) => {
    console.log("Reading from presigned URL")

    return axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/html'
            }})
}

// This will hit the OpManAPI querying for a `dashboard_id`, returning the
// dashboard HTML.
export function DashFunctionPage(dashboard_id: string){
    console.log(dashboard_id)

    const dispatch = useDispatch()

    const [errors, setErrors] = useState<boolean|null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const default_binary_html = "<h3> waiting </h3>"
    const [dashboard, setDashboard] = useState<string>(default_binary_html)


    useEffect(() => {
        setLoading(true)
        //console.log("dashboard is" + dashboard)
        getDashboard(dashboard_id)
            .then(response => {
                console.log("dashboard is ID:" + dashboard_id)
                console.log("response:")
                console.log(response.data)
                if(response.status === 200){

                    // Hacky redirect approach.
                    console.log("redirecting to presigned url")
                    window.location.replace(response.data.presigned_url);

                    // TODO: re-enable this to render in iframe (when CORS works again)
                    // readPresignedUrl(response.data.presigned_url, credential)
                    //     .then(resp => {
                    //         console.log("Presigned URL status: " + response.status)
                    //         if (response.status === 200) {
                    //             setDashboard(resp.data.toString())

                    //         } else {
                    //             setDashboard("<h3> error reading from presigned S3 URL </h3>")
                    //         }
                    //     })

                } else
                    // TODO: improve this handling...
                {
                    setDashboard(
                        "<h3> error loading api request. status: " + response.status + "</h3>"
                    )
                }
            })
            .catch((error) => {
                setErrors(true)
            })
            .finally(() => setLoading(false))
    }, [dashboard_id, dispatch])

    return{loading, errors, dashboard}

}


export default function Dashboards(){

    const params = useParams();
    // NOTE: this doesn't handle `undefined` well & will 404
    let dashboard_id = params.dashboard_id
    const {loading, errors, dashboard} = DashFunctionPage(dashboard_id!)

    // TODO: revise error & loading handling (below is dummy from another page)
    // if(loading) return <React.Fragment><TableLoad /> <TableLoad /> <TableLoad /> <TableLoad /></React.Fragment>
    // if(errors) return <p className='p-table-msg'>Internal server error</p>
    // Temporary logging...
    console.log(loading)
    console.log(errors)

    let html : string = unwrapDashboardContents(dashboard)
    return(
        <div dangerouslySetInnerHTML={iframe_srcdoc(html)} />
    )
}
