import useFleetExecutions from "../../hooks/useFleetExecutions";
import useIngestSchedule from "../../hooks/useIngestSchedule";
import SimpleLoader from "../Loader/SimpleLoader";
import UpdateIcon from '../icons/update_icon'
import ModalPortal from '../Modal'
import ModalFleetExecutionTask from '../Modal/ModalFleetExecutionTask'
import FilterWeeklyRefresh from "./FilterWeeklyRefresh";
import HeaderFleetExecutionsTable from "./HeaderFleetExecutionsTable";
import React from "react";
import FleetExecution from "./FleetExecution";
import usePopUp from "../../hooks/usePopUp";
import ModalAbortExecution from '../Modal/ModalAbortExecution'
import IngestSchedule from './IngestSchedule'

export default function WeeklyRefreshCC({ fleet_execution_id = '' }){
  const { loading, tFleetExecutions, viewCancelPopUp, refreshTables } = useFleetExecutions();
  const { scheduleState } = useIngestSchedule();
  const {viewPopUp, handdleViewPopUp} = usePopUp()

  const RenderSingleFleetExecution = (executionId: string) => {
    const expandRows = (executionId.length > 0);
    let execution_elem = tFleetExecutions.filter((elem:any) => Object.keys(elem.tags).includes("_weekly_refresh"))
    if(executionId.length > 0){
      execution_elem = tFleetExecutions.filter((elem:any) => elem.fleet_execution_id === executionId)
    }

    return (
      <>
        <HeaderFleetExecutionsTable />
          {
            execution_elem.map((execution:any, index: number) => {
              return(
                <React.Fragment key={`${execution.fleet_execution_id}${index}`}>
                  <FleetExecution execution={execution} expandRows={expandRows} handdleViewPopUp={handdleViewPopUp}/>
                </React.Fragment>
              )
            })
          }
      </>
    )
  }

  return (
    <>
      <div className='refresh-button' onClick={refreshTables}>
        {loading? (<SimpleLoader />) : (<UpdateIcon width='18px' height='18px' />)}
        <p>Refresh</p>
      </div>

      {
        viewCancelPopUp &&
        <ModalPortal>
          <ModalAbortExecution />
        </ModalPortal>
      }

      {
        viewPopUp.view && viewPopUp.data &&
        <ModalPortal>
          <ModalFleetExecutionTask viewPopUp={viewPopUp} handdleViewPopUp={handdleViewPopUp}/>
        </ModalPortal>
      }

      <IngestSchedule schedule={scheduleState?.schedule} />

      <FilterWeeklyRefresh />

      <section>
        {tFleetExecutions && tFleetExecutions.length !== 0 ? (
          RenderSingleFleetExecution(fleet_execution_id)
        ) : loading? (<></>) : (
          <p className="p-table-msg">No data</p>
        )}
      </section>
    </>
  );
};


