import Filter from '../../Util/Filter'
import useFilterFleetExecutions from '../../../hooks/useFilterFleetExecutions'
import useDynamicFilter from '../../../hooks/useDynamicFilter'
import { addFilter } from "../../../redux/executionsSlice"

export default function ExecutionFilter({objKey, name}: any){

    const {view, setView, arrayAux} = useFilterFleetExecutions(objKey)
    const {deleteFilter, handdleFilters, ref, filters} = useDynamicFilter({view, setView, addFilter, stateObj: 'executions'})

    return(
        <>
            <Filter
                view={view}
                setView={setView}
                arrayAux={arrayAux}
                deleteFilter={deleteFilter}
                filters={filters}
                handdleFilters={handdleFilters}
                refParam={ref}
                type={name}
                keyParm={objKey}
            />
        </>
    )
}
