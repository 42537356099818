import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addFleet } from "../redux/weeklyRefreshSlice"
import getFleetExecutionTask from '../services/getFleetExecutionTask'

export default function useWeeklyRefreshFleet(fleet_execution_id: string){
  
  const [fleetState, setFleetState] = useState<any>(null)
  const [errors, setErrors] = useState<boolean|null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [viewFleet, setViewFleet] = useState(false)

  let weeklyRefresh: any = useSelector((state: any) => state.weeklyRefresh)
  
  const dispatch = useDispatch()

  const found:any = weeklyRefresh?.fleets[fleet_execution_id]

  useEffect(() => {
    setLoading(true)    
    const min_age = Date.now() - 30 * 1000 // 30 seconds
    if (!found || found.time_added < min_age) {
      getFleetExecutionTask(fleet_execution_id)
        .then(response => {
          if(response.status === 200){  
            const tasks = response.data.execution_tasks
            const fleet_data = {[fleet_execution_id]: {
              depvars: tasks.filter((t:any) => t.machine_name === "standard_fleet"),
              ingest: tasks.filter((t:any) => t.machine_name === "multi_depvar"),
              deploy: tasks.filter((t:any) => t.machine_name === "standard_deploy"),
              time_added: Date.now()
            }};
            dispatch(addFleet(fleet_data));
            setFleetState(fleet_data)
          }
        })
        .catch((error) => {
          setErrors(true)
        })
        .finally(() => setLoading(false))
    } else {
      setFleetState(found)
      setLoading(false)
    }
  },[fleet_execution_id, dispatch, found])

  const handleView = () => {
    setViewFleet(!viewFleet)
  }

  return{fleetState, errors, loading, viewFleet, handleView}
}
