import FirstColumn from './FirstColumn'
import SecondColumn from './SecondColumn'
import { useEffect, useState } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'

export default React.memo(function Header():JSX.Element{

    const [state, setState] = useState<boolean>(false)
    const configSize = useSelector((state: any) => state.config.size)

    useEffect(() => {
        setState(true)
    },[])


    return(
        <header className={`${configSize === 'bigMain'? 'bigNav':'smallNav'}`}>
            <FirstColumn />
            <SecondColumn />
        </header>
    )
})
