import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from 'react-redux'


export default function useDynamicFilter({view, setView, addFilter, stateObj}: any){
  const ref:any = useRef()
  const filters = useSelector((state: any) => state[stateObj].filters)
  const dispatch = useDispatch()

  useEffect(() => {
    const checkIfClickedOutside = (e:any) => {
      if (view && ref.current && !ref.current.contains(e.target)) {
        setView(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [view, setView])

  const deleteFilter = (filterType:string) =>{
    const newFilter:any = {
      [filterType]: null
    }
    dispatch(addFilter({ ...filters, ...newFilter}))
    setView(false)
  }

  const handdleFilters = (filterString: string, filterType: string, exclude: boolean = false):void => {
    const newFilter:any = {
      [filterType]: filterString?{ filter: filterString, exclude: exclude}:null
    }
    dispatch(addFilter({ ...filters, ...newFilter}))
    setView(false)
  }

  const handdleClearAll = ()=>{
    const filterRouter:any = {
      ...filters, client: null, status: null, aws_username:null, tags:null
    }
    dispatch(addFilter(filterRouter))
    setView(false)
  }

  return {deleteFilter, handdleFilters, ref, filters,handdleClearAll}
}
