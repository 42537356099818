import RecastLogo from '../icons/recast_logo'
import RecastSmallLogo from '../icons/recast_small_logo'
import { useSelector } from 'react-redux'

export default function FirstColumn():JSX.Element{
    const configSize = useSelector((state: any) => state.config.size)
    return(
        <section className='header-first-column'>
            {   configSize === 'bigMain'?
                    <RecastLogo width='100px' height='44px' />:
                    <RecastSmallLogo width='30px' height='30px' />
            }
        </section>
    )
}
