import { IFleetExecutionTask } from "../../TableFleetExecutions/TableFleetExecutionTask/FleetExecutionTask";
import TableRows from "./TableRows";
import HoverText from "../../Util/HoverText";

export interface IDepvarProps {
  executionTask: IFleetExecutionTask;
  dashboardsState: any;
  eventsState: any;
  handleViewPopUp:(value: boolean, data: any) => void
}

export default function Depvar({ executionTask, dashboardsState, eventsState, handleViewPopUp}: IDepvarProps) {

  //if (loading) return <TableLoad />;
  //if (errors) return <p className="p-table-msg">Internal server error</p>;
  let dashboards = []
  if (dashboardsState && dashboardsState.dashboards) {
    dashboards = dashboardsState.dashboards
  }
  let events = []
  if(eventsState && eventsState.events) {
    events = eventsState.events.filter((e:any) => e.fleet_execution_task_id === executionTask.fleet_execution_task_id)
  }

  return (
    <div className="container-fleets">
      <div className="tableFleets weekly-refresh-task">
        <TableRows
          fleet_execution_id={executionTask.fleet_execution_id}
          fleet_execution_task_id={executionTask.fleet_execution_task_id}
          machine_name={executionTask.machine_name}
          status={executionTask.status}
          start_date={executionTask.start_date}
          end_date={executionTask.end_date}
          links = {executionTask.links}
          primary_task = {executionTask.primary_task}
          machine_input = {executionTask.machine_input}
          handleViewPopUp={handleViewPopUp}
        />
      </div>
      <div className="depvarContainer">
        <div className="depvarCard">
          { dashboards.length > 0 ? (<h3>Dashboards</h3>) : null }
          {
            dashboards.filter((d:any) => 
              d.dashboard_label === executionTask.machine_input.depvar && d.dashboard_type === 'run_analysis'
            ).map((d:any) =>
              (
                <p key={d.dashboard_id}>
                  <HoverText text={Object.keys(d.context)?.map((k) => `* ${k}: ${d.context[k]}`)}>
                    <a rel="noreferrer" target={'_blank'} href={'/dashboard/'+d.dashboard_id}>
                      {(d.autogenerated?'* ':'  ') + d.dashboard_type}
                    </a>
                  </HoverText>
                </p>
              )
            )
          }
        </div>
        <div className="depvarCard">
          {
            events.filter((e:any) =>
              e.topic === 'stability_metrics'
            ).map((e:any) => (<><h3>Stability</h3>{e.details?.stability}</>))
          }
        </div>
      </div>
    </div>
  )
}
