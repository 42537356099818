import useTableRowsFleetExecutionTask from '../../../hooks/useTableRowsFleetExecutionTask'
import { IFleetExecutionTask } from './FleetExecutionTask'
import copyClipboard from "../../../utils/copyClipboard";
import Link from "../../Util/Link";

export default function TableRows({fleet_execution_id,fleet_execution_task_id,end_date,links,start_date,status,machine_name,primary_task,machine_input,handleViewPopUp}: IFleetExecutionTask){
    const {handdleView, handdleCancel} = useTableRowsFleetExecutionTask(fleet_execution_id,fleet_execution_task_id,machine_name,status,start_date,end_date,primary_task,links,machine_input,handleViewPopUp)
    const extra_class = primary_task ? "primary-task" : "";

    const input_title = machine_input["title"]
    const depvar = machine_input["depvar"]
    const stacking_path = machine_input["stacking"]
    const task_name = machine_name + (depvar ? ": " + depvar : '') + (input_title ? ': ' + input_title : '')

    return(
        <>
            <div className={'tbody copy-text ' + extra_class} onClick={(e:any) => copyClipboard(stacking_path, "stacking path", e)}><p id={fleet_execution_task_id}>{task_name}</p></div>
            <div className='tbody'><div className={`status ${status? status.toLowerCase(): ''}`}><p>{status}</p></div></div>
            <div className='tbody'><p>{start_date}</p></div>
            <div className='tbody'><p>{end_date}</p></div>
            <div className='tbody t-link'>
                {
                    links.slice(0, 2).map((link:any) => (<Link key={`${fleet_execution_id}${link.url}`} label={link.label} url={link.url} />))
                }
            </div>
            <div className='tbody'><p>{fleet_execution_task_id}</p></div>
            <div className='tbody-links tbody tbody-detail actions'>
                {
                    (status.toLowerCase() === 'running')?
                    <button onClick={handdleCancel} className='button-link-cont delete'>
                        { primary_task ? "Abort Fleet" : "Abort" }
                    </button>
                    : <div className='delete-btn'></div>
                }
                <button onClick={handdleView} className='button-link-cont'>
                    View Details
                </button>
            </div>
        </>
    )
}
