import LogIcon from "../icons/log_icon";
import NetworkIcon from "../icons/network_icon";

export default function Link ({label, url}:any) {
  return(
      <div className='tbody-links'>{
        <a onClick={e => e.stopPropagation()} href={url} target='_blank' rel='noreferrer' className={`img-link-cont ${label?.toLowerCase()}`}>{
          label === 'CloudWatch'?
            <LogIcon width='1.6em' height='1.6em' />
          : label === 'StepFun'?
            <NetworkIcon width='1.3em' height='1.3em' />
          :<span>{label?.substring(0, 4)}</span>
        }</a> 
      }</div>
   )
}
