import useJobSizes from "../../hooks/useJobSizes";
import SimpleLoader from "../Loader/SimpleLoader";
import ModalPortal from '../Modal'
import ModalJobSizes from '../Modal/ModalJobSizes'
import usePopUp from "../../hooks/usePopUp";
import UpdateIcon from '../icons/update_icon'
import FilterJobSize from "./FilterJobSize";
import HeaderJobSize from "./HeaderJobSize";
import React from "react";
import ClientJobSize from "./ClientJobSize";

export default function JobSizes({ codename = '' }){
  const {viewPopUp, handdleViewPopUp} = usePopUp()
  const { loading, sizesState, viewEditPopUp, tJobSizes, refreshTables } = useJobSizes();

  const RenderSingleClient = (codename: string) => {
    const size_elem = tJobSizes[codename]
    if(size_elem){
      return ( <ClientJobSize codename={codename} jobSizes={size_elem} handdleView={handdleViewPopUp} key={codename+'row'}/> )
    } else {
      return null
    }
  }

  const clients = (codename? [codename] : tJobSizes? Object.keys(tJobSizes) : []).sort()

  return (
    <>
      <div className='refresh-button' onClick={refreshTables}>
        {loading? (<SimpleLoader />) : (<UpdateIcon width='18px' height='18px' />)}
        <p>Refresh</p>
      </div>

      <FilterJobSize />

      <section>
        <HeaderJobSize />
        {sizesState?.default &&
        (<ClientJobSize codename={"Defaults"} jobSizes={sizesState.default} handdleView={null} />)}
        {tJobSizes && tJobSizes.length !== 0 ? (
          clients.map(RenderSingleClient)
        ) : loading? (<></>) : (
          <p className="p-table-msg">No data</p>
        )}
      </section>

      {
        viewEditPopUp &&
        <ModalPortal>
          <ModalJobSizes />
        </ModalPortal>
      }

    </>
  );
};


