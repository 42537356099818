import CopyIcon from "../icons/copy_icon";
import copyClipboard from "../../utils/copyClipboard";

export interface ICopyTextProps {
  title: string;
  data: any;
  type?: string;
}

export default function CopyText({title, data, type}: ICopyTextProps) {
  const tType = type? type: "code"
  return (
    <div className={"copy-text"} onClick={(e:any) => copyClipboard(data, tType, e)}>
      <CopyIcon width={"1em"} height={"1em"} fill={"black"}/>
      {title}
    </div>
  )
}
