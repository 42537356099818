import {Navigate} from 'react-router-dom'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'

export interface LoginRouteProp{
    children: JSX.Element
}

export default function LoginRoute({ children }:LoginRouteProp) {
    const user = useContext(UserContext).user;
    return !user ? children : <Navigate to="/" />
}