import { fetchAuthSession, signOut } from 'aws-amplify/auth';

export default async function fetchAccessToken() {
    try {
        const authSession = await fetchAuthSession();
        const token = authSession.tokens?.accessToken.toString();

        if (!token) {
            throw new Error('No token present');
        }

        return token;
    } catch (error) {
        await signOut();
        console.error((error as Error).message);
    }
}
