import DashboardIcon from '../icons/grid_icon'
import JobSizes from '../JobSizes'
import { useParams } from "react-router-dom";

export default function ClientJobSizes(){
  const params = useParams();
  return(
    <section className='content'>
      <div className='first-row'>
        <div className='container-icon'>
          <DashboardIcon width='17px' height='17px' fill='white' />
        </div>
        <h1>Client Job Sizes</h1>
      </div>
      <div className='table-container'>
        <JobSizes codename={params.codename} />
      </div>
    </section>
  )
}
