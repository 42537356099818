import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addFleetExecutionTask } from "../redux/executionTasksSlice"
import getFleetExecutionTask from '../services/getFleetExecutionTask'
import sortedFleetExecutionTasks from '../utils/sortedFleetExecutionTasks'


export default function useFleetExecutionsTask(fleet_execution_id: string){

  const [executionTaskState, setFleetExecutionTaskState] = useState<any>(null)
  const [errors, setErrors] = useState<boolean|null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [viewFleetExecutionTask, setViewsetFleetExecutionTask] = useState(false)

  let executionTask: any = useSelector((state: any) => state.executionTask)

  const dispatch = useDispatch()

  const found:any = executionTask?.executionTask[fleet_execution_id]

  useEffect(() => {
    setLoading(true)
    const min_age = Date.now() - 60 * 1000 // 60 seconds
    const sortedList = (list:any) => sortedFleetExecutionTasks(list, executionTask.filters)
    if(!found || found.time_added < min_age){
      getFleetExecutionTask(fleet_execution_id)
        .then(response => {
          if(response.status === 200){
            const fleet_data = {[fleet_execution_id]: {
              fleet_execution_id: fleet_execution_id,
              executionTask: response.data.execution_tasks,
              time_added: Date.now()
            }}
            dispatch(addFleetExecutionTask(fleet_data))
            setFleetExecutionTaskState(fleet_data)
          }
        })
        .catch((error) => {
          setErrors(true)
        })
        .finally(() => setLoading(false))
    } else if(found.executionTask && found.executionTask.length > 0) {
      let sortList = sortedList(found.executionTask)
      setFleetExecutionTaskState({fleet_execution_id: fleet_execution_id, executionTask: sortList})
      setLoading(false)
    }
  },[fleet_execution_id, executionTask.filters, dispatch, found])

  const handdleView = () => {
    setViewsetFleetExecutionTask(!viewFleetExecutionTask)
  }

  return{executionTaskState, errors, loading, viewFleetExecutionTask, handdleView}
}
