import PersonIcon from '../icons/person_outline_icon'
import CalendarIcon from '../icons/update_icon copy'
import SimpleLoader from '../Loader/SimpleLoader'
import useModalAbort from '../../hooks/useModalAbort'

export default function ModalAbortExecution(){

    const {dataTerminate, text, setText, cancelMd, confirmMd, loading } = useModalAbort()

    return(
        <div className='modal-delete-container'>
            <div className='modal-dialog-confirm'>
                <p className='title-md'>Confirm termination of fleet execution</p>
                <div className='md-item'>
                    <span>
                        <PersonIcon width='1rem' height='1rem' />
                    </span>
                    <p>Task: {dataTerminate.machine_name}</p>
                </div>
                <div className='md-item'>
                    <span>
                        <PersonIcon width='1rem' height='1rem' />
                    </span>
                    <p>Client: {dataTerminate.client}</p>
                </div>
                <div className='md-item'>
                    <span>
                        <CalendarIcon width='1rem' height='1rem' />
                    </span>
                    <p>Start Date: {dataTerminate.start_date}</p>
                </div>
                <p>Are you sure you want to terminate the fleet execution task?</p>
                <h4>{dataTerminate.primary_task? "Since this is a primary task, all remaining tasks in this fleet will also be terminated": ""}</h4>
                <div className='container-buttons-md'>
                    <button onClick={() => cancelMd()}>Cancel</button>
                    <button className={`confirm-btn-md 'confirm-md-enabled'`}
                            onClick={() => confirmMd()}>
                                {
                                    loading? 
                                    <SimpleLoader /> :
                                    <span>Confirm</span>
                                }
                    </button>
                </div>
            </div>
        </div>
    )
}
