import { ILink } from '../../hooks/useFleetExecutions'
import LayersIcon from '../icons/layers_icon'
import TagsIcon from '../icons/tags_icon'

export interface IFleetExecution{
    aws_username: string,
    client: string,
    status: string,
    start_date: string,
    end_date: string,
    fleet_execution_id: string,
    machine_name: string,
    links: ILink[],
    tags: any,
}

export default function TableRows({aws_username, fleet_execution_id, status, start_date, end_date, client, machine_name, links, tags}: IFleetExecution){

/*     const {handdleView, handdleTerminate} = useTableRowsFleetsRuns(fleet_run_id, status, start_date, end_date, client, links, user, experimental, handdleViewPopUp) */

    return(
        <>
            <div className='tbody'><p>{aws_username}</p></div>
            <div className='tbody'><p>{client}</p></div>
            <div className='tbody'><div className={`status ${status? status.toLowerCase(): ''}`}><p>{status}</p></div></div>
            <div className='tbody'><p>{start_date}</p></div>
            <div className='tbody'><p>{end_date}</p></div>
            <div className='tbody t-link'>
                <TagsIcon width='18px' height='18px' />
                {
                    Object.keys(tags).map((tag: string) => {
                        const v = tags[tag]
                        const vtype = typeof(v)
                        const classtype = vtype === 'boolean'? v : vtype
                        return(
                            <div key={`${fleet_execution_id}${tag}`} className='tbody-links'>
                                {
                                   <span className={`img-link-cont type-${classtype}`}>
                                       {tag}
                                   </span>
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className='tbody'><LayersIcon width='18px' height='18px' /><p>{fleet_execution_id}</p></div>
        </>
    )
}
