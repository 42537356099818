import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addJobSize, setViewEdit } from "../redux/clientsSlice"
import setJobSizes from '../services/setJobSize'

export default function useModalEditJobSizes(){
  const [loading, setLoading] = useState<Boolean>(false)

  const dataEdit = useSelector((state: any) => {
    return state.clients.dataEdit;
  })
  const jobSizes: any = useSelector((state: any) => state.clients.jobSizes)
  const [form, setForm] = useState({ client: dataEdit.codename, run_type: dataEdit.run_type, ...dataEdit.jobSizes[dataEdit.run_type] })
  const dispatch = useDispatch()

  const cancelMd = () => {
    dispatch(setViewEdit({view: false, data: null}))
  }

  const confirmMd = () => {
    setLoading(true)
    setJobSizes(form.client, form.run_type, form.memory, form.cpu)
    .then(
      response =>{
        if(response.status === 200){
          console.log(response.data)
          dispatch(addJobSize(form))
        }
        dispatch(setViewEdit({view: false, data: null}))
      }
    )
    .catch(error => {
      if(error?.response?.status === 401){
        console.log("error 401", error)
      }
      console.log(error)
      dispatch(setViewEdit({view: false, data: null}))
    })
    .finally(() => setLoading(false))
  }

  return {cancelMd, confirmMd, dataEdit, form, setForm, loading}
}
