export default function getInitials(name: string | undefined): string {
    if(name)  {
      const parts = name.split(' ');
      let initials = parts[0][0];
    
      if (parts.length > 1) {
        initials += parts[1][0];
      }
    
      return initials.toUpperCase();
    } else {
      return '';
    }
  }