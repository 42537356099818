import React, { useState, useEffect, ReactChild } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

interface IChildren {
  children: ReactChild,
}

interface IUser {
  fullName: string;
  email: string;
}

interface IUserContext {
  user: IUser | null;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

const defaultSetUser: React.Dispatch<React.SetStateAction<IUser | null>> = () => {};

const defaultContextValue: IUserContext = {
  user: null,
  setUser: defaultSetUser,
};

const UserContext = React.createContext<IUserContext>(defaultContextValue);

export function UserContextProvider({ children }: IChildren) {
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    fetchAuthSession()
      .then(fetchedToken => {
        const idTokenPayload = fetchedToken?.tokens?.idToken?.payload;
        const user: IUser = {
          fullName: `${idTokenPayload?.given_name} ${idTokenPayload?.family_name}`,
          email: `${idTokenPayload?.email}`
        };
        setUser(user);
      }).catch(() => setUser(null));
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}


export default UserContext;
