import Filter from '../../Util/Filter'
import useFilterDataClients from '../../../hooks/useFilterDataClients'
import useDynamicFilter from '../../../hooks/useDynamicFilter'
import { addFilter } from "../../../redux/clientsSlice"

export default function ClientsFilter(){
  const {view, setView, arrayAux} = useFilterDataClients()
  const {deleteFilter, handdleFilters, ref, filters} = useDynamicFilter({view, setView, addFilter, stateObj: 'clients'})

  return(
    <>
      <Filter
        view={view}
        setView={setView}
        arrayAux={arrayAux}
        deleteFilter={deleteFilter}
        filters={filters}
        handdleFilters={handdleFilters}
        refParam={ref}
        type={'Clients'}
        keyParm = {'client'}
      />
    </>
  )
}
