import './scss/Login.scss'
import Particles from './components/Particles'
import { signInWithRedirect } from 'aws-amplify/auth';
import { Button } from '@aws-amplify/ui-react';

export default function Login(): JSX.Element{
    const provider = {
        custom: 'RecastGoogleWorkspace'
      }
      

    return(
        <>
            <div className="app">
                <div className='container-login'>
                    <h1>Recast Login</h1>
                    <div id="buttonDiv"></div>
                    <Button variation="primary" isFullWidth={true} onClick={() => signInWithRedirect({ provider })}>
                        Login
                    </Button>
                </div>
                <Particles />
            </div>     
        </>
    )
}
