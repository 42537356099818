import React from 'react'
import LiProfile from './LiProfile'
import LiElement from './LiElement'
import CircleElement from './CircleElement'
import FleetRunIcon from '../icons/app_registration_icon'
import LayersIcon from '../icons/layers_icon'
import TuneIcon from '../icons/tune_icon'

import { useSelector } from 'react-redux'

export default React.memo(function LeftNav():JSX.Element{

    const configSize = useSelector((state: any) => state.config.size)

    return(
        <nav className={configSize === 'bigMain'? 'bigNavLeft': 'smallNavLeft'}>
            <ul>
                <LiProfile />
                <LiElement name={'Fleet Executions'} configSize={configSize} url={'FleetExecutions'}>
                    <FleetRunIcon width='20px' height='20px' />
                </LiElement>
                <LiElement name={'Weekly Refresh'} configSize={configSize} url={'WeeklyRefresh'}>
                    <LayersIcon width='20px' height='20px' />
                </LiElement>
                <LiElement name={'Job Sizes'} configSize={configSize} url={'JobSizes'}>
                    <TuneIcon width='20px' height='20px' />
                </LiElement>
            </ul>
        </nav>
    )
})
