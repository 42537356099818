import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function useFilterDataFleetExecutions(type:string){
    const [view, setView] = useState<boolean>(false)
    const executions = useSelector((state: any) => state.executions.executions)

    let arrayAux:string[] = []

    executions && executions.forEach((execution: any) => {
        if(!execution[type]) {
            return null
        }
        const vals = typeof(execution[type]) == "object" ?
            Array.isArray(execution[type])?
                execution[type]
                :Object.keys(execution[type])
            : [execution[type]] // primitive
        vals.forEach((v:any) => { if(!arrayAux.includes(v.toLowerCase())) arrayAux = [...arrayAux, v.toLowerCase()] })
    })
    arrayAux = arrayAux.sort()

    return{view, setView, arrayAux}
}
