export default function copyClipboard(copy_text:string, data:string,  ev:any) {
    const elem:any = ev.currentTarget
    ev.stopPropagation()
    navigator.clipboard.writeText(copy_text)
    if(elem && copy_text){
        const orig = elem.innerHTML
        elem.innerHTML = data + ' copied'
        setTimeout(() => {
            elem.innerHTML = orig
        }, 1000)
    }
}
