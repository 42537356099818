import MenuButton from './MenuButton'
import UserSection from './UserSection'
import Logout from './Logout'
import React from 'react'

export default React.memo(function SecondColumn(): JSX.Element{
    return(
        <section className='header-second-column'>
            <MenuButton />
            <UserSection />
            <Logout />
        </section>
    )
})
