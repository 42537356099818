import axios from 'axios';
import getEncodedId from '../utils/getEncodedId';
import fetchAccessToken from '../utils/fetchAccessToken';

const deleteFleetExecutionTask = async(fleet_execution_id:string, fleet_execution_task_id:string) => {
   const token = await fetchAccessToken();
   return axios.delete(`${process.env.REACT_APP_URL}/v2/user/fleet_execution/${getEncodedId(fleet_execution_id)}/fleet_execution_task/${getEncodedId(fleet_execution_task_id)}`,{
      headers: {
         'Content-Type' : 'application/json',
         'Authorization': `Bearer ${token}`
      },
      timeout: 60000
   })
}

export default deleteFleetExecutionTask;
