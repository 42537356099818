import { useCallback, useState } from 'react'

export default function useView(){
    const[view, setView] = useState(false)

    const handdleView = useCallback(() => {
        setView((view) => !view)
    }, [])

    return {view, handdleView}
}
