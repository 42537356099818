import TableRows, { IFleetExecution } from './TableRows'
import HoverText from "../Util/HoverText";
import IngestIcon from '../icons/ingest_icon'
import ModelRunIcon from '../icons/model_run_icon'
import DeployIcon from '../icons/deploy_icon'
import CircleIcon from '../icons/circle_icon'

export interface IStatusProps {
  execution: IFleetExecution;
}

export default function Status({ execution }: IStatusProps){

  // data issue, run failed, running, deploy scheduled, deploy failed, done(deployed)
  const deploy = execution?.task_status.find((t:any) => Object.keys(t)[0] === 'standard_deploy')
  const ingest = execution?.task_status.find((t:any) => Object.keys(t)[0] === 'multi_depvar')
  const model = execution?.task_status.find((t:any) => Object.keys(t)[0] === 'standard_fleet')
  const fill_map:any = {
    'running': '#007cb9',
    'failed': '#f5310e',
    'succeeded': '#42b883',
    'aborted': '#c7c72a'
  }
  const lower_status = execution.status.toLowerCase()
  const total_fill = fill_map[lower_status] || '#ac75ff'

  const text_map:any = {
    'running': 'Running',
    'failed': 'Failed during',
    'succeeded': 'Finished after',
    'aborted': 'Aborted during'
  }
  const task_name =
    deploy?'deploy':
    model?'model runs':
    ingest?'data ingest':
    'unknown task';
  const status_text = text_map[lower_status] || lower_status
  const dim = '22px'

  return (
    <p><HoverText text={[`${status_text} ${task_name}`]}>
      <CircleIcon width={dim} height={dim} fill={total_fill}/>
      {
        ingest?<IngestIcon width={dim} height={dim} fill='#000000' />:null
      }
      {
        model?<ModelRunIcon width={dim} height={dim} fill='#000000' />:null
      }
      {
        //const deploy_fill = fill_map[deploy_status]
        deploy?<DeployIcon width={dim} height={dim} fill='#000000' />:null
      }
    </HoverText></p>)
}
