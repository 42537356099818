import { createSlice } from '@reduxjs/toolkit'

export const configSlice = createSlice({
  name: "configSlice",
  initialState: {
    size: 'bigMain',
    jwt: null
  },
  reducers: {
    updateSize: (state, action) => {
      state.size = action.payload
    },
    setJwt: (state, action) => {
      state.jwt = action.payload
    }
  },
});

export const { updateSize, setJwt } = configSlice.actions
export default configSlice.reducer