import useTableRowsFleetExecutionTask from '../../hooks/useTableRowsFleetExecutionTask'
import { ILink } from '../../hooks/useFleetExecutions'
import LayersIcon from '../icons/layers_icon'
import TagsIcon from '../icons/tags_icon'

export interface IFleetExecution{
  aws_username: string,
  client: string,
  status: string,
  task_status: any,
  start_date: string,
  end_date: string,
  fleet_execution_id: string,
  machine_name: string,
  links: ILink[],
  tags: any,
  handleViewPopUp: any,
}

export default function TableRows({aws_username, fleet_execution_id, status, task_status, start_date, end_date, client, machine_name, links, tags, handleViewPopUp}: IFleetExecution){

  const {handdleCancel} = useTableRowsFleetExecutionTask(fleet_execution_id,fleet_execution_id,machine_name,status,start_date,end_date,true,links,{},handleViewPopUp)

  return(
    <>
      <div className='tbody'><p>{client}</p></div>
      <div className='tbody'><p>{task_status}</p></div>
      <div className='tbody'><p>{start_date}</p></div>
      <div className='tbody'><p>{end_date}</p></div>
      <div className='tbody t-link'>
        <TagsIcon width='18px' height='18px' />
        {
          Object.keys(tags).map((tag: string) => {
            const v = tags[tag]
            const vtype = typeof(v)
            const classtype = vtype === 'boolean'? v : vtype
            return(
              <div key={`${fleet_execution_id}${tag}`} className='tbody-links'>
                {
                   <span className={`img-link-cont type-${classtype}`}>
                     {tag}
                   </span>
                }
              </div>
            )
          })
        }
      </div>
      <div className='tbody-links tbody tbody-detail actions'>
        {
          (status.toLowerCase() === 'running')?
          <button onClick={handdleCancel} className='button-link-cont delete'>
            { "Abort Fleet" }
          </button>
          : <div className='delete-btn'></div>
        }
      </div>
    </>
  )
}
