import { useCallback, useEffect, useState } from 'react';       
import { useSelector, useDispatch } from 'react-redux';
import { addFleetExecutions } from '../redux/executionsSlice';
import getFleetExecutions from '../services/getFleetExecutions';

export interface ILink{
    label: string,
    url: string
}

const useFleetExecutions = () => {
    const executions = useSelector((state: any) => state.executions.executionsFilters);

    const dispatch = useDispatch();

    const [errors, setErrors] = useState<string|null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<any>(null);
    const [refresh, setRefresh] = useState<boolean>(false);

    const handdleRefresh = useCallback(() => {
        setFilters(null)
        setRefresh(rfresh => !rfresh)
    },[dispatch]);

    useEffect(() => {
        setLoading(true)
        getFleetExecutions()
        .then(response => {
            if(response.status === 200){
                dispatch(addFleetExecutions(response.data?.executions))
            }
        })
        .catch(error => {
            setErrors(error?.response?.data ? error.response.data?.message : "Unknown error")
        })
        .finally(() => {
            setLoading(false)
        })

        return () => {
            setErrors(null)
            setLoading(false)
        }
    },[refresh, handdleRefresh, dispatch])

    const viewCancelPopUp = useSelector((state: any) => {
        return state.executions.viewCancelPopUp || state.executionTask.viewCancelPopUp;
    });

    const refreshTables = () => {
        dispatch(addFleetExecutions(null))
        handdleRefresh()
    }

    return {
        tFleetExecutions: executions,
        errors, loading, filters, setFilters, handdleRefresh,
        refreshTables, viewCancelPopUp
    }
}

export default useFleetExecutions;
