export default function TableLoad(){
    return(
        <>
            <div className='tableLoad'>
                <div className='tload thead'><p></p></div>
                <div className='tload thead'><p></p></div>
                <div className='tload thead'><p></p></div>
                <div className='tload thead'><p></p></div>
                <div className='tload thead'><p></p></div>
                <div className='tload thead'><p></p></div>

                <div className='tload'><p></p></div>
                <div className='tload'><p></p></div>
                <div className='tload'><p></p></div>
                <div className='tload'><p></p></div>
                <div className='tload'><p></p></div>
                <div className='tload'><p></p></div>
            </div>
        </>
    )
}