import PersonIcon from '../icons/person_outline_icon'
import CalendarIcon from '../icons/update_icon copy'
import SimpleLoader from '../Loader/SimpleLoader'
import useModalEditJobSizes from '../../hooks/useModalEditJobSizes'

export default function ModalJobSizes(){
  const {dataEdit, form, setForm, cancelMd, confirmMd, loading } = useModalEditJobSizes()

  const handleChange = (n:string, e:any) => {
    if(n === 'run_type') {
      const orig = dataEdit.jobSizes[e.target.value]
      const vals = orig? orig : { memory: 0, cpu: 0}
      setForm({ ...form, run_type: e.target.value, ...vals })
    } else {
      const val = parseInt(e.target.value)
      if(val) {
        setForm({ ...form, [n]: val })
      }
    }
  };

  return(
    <div className='modal-delete-container'>
      <div className='modal-dialog-confirm'>
        <p className='title-md'>Edit {form.client}'s job resources</p>
        <div className='md-item'>
          <p>Run type:</p>
          <select value={form.run_type} onChange={(e) => handleChange('run_type', e)}>
            <option value="model_run">Model run</option>
            <option value="stacking">Stacking</option>
            <option value="backtesting">Backtesting</option>
            <option value="dashboard_build">Dashboard building</option>
          </select>
        </div>
        <div className='md-item'>
          <p>Memory (MiB):</p>
          <input size={6} value={form.memory} onChange={(e) => handleChange('memory', e)} />
        </div>
        <div className='md-item'>
          <p>CPU:</p>
          <input size={2} value={form.cpu} onChange={(e) => handleChange('cpu', e)} />
        </div>
        <div className='container-buttons-md'>
          <button onClick={() => cancelMd()}>Cancel</button>
          <button className={`confirm-btn-md 'confirm-md-enabled'`}
            onClick={() => confirmMd()}>
            {
              loading? 
              <SimpleLoader /> :
              <span>Confirm</span>
            }
          </button>
        </div>
      </div>
    </div>
  )
}
