import React from "react";
import HeaderFleetExecutionTask from "./HeaderFleetExecutionTask";
import TableLoad from "../../TableLoad";
import useFleetExecutionsTask from "../../../hooks/useFleetExecutionsTask";
import FleetExecutionTask from "./FleetExecutionTask";

interface ITableFleetExecutionTaskProps {
  fleet_execution_id: string;
  expandRows?: boolean;
  handdleViewPopUp: (value: boolean, data: any) => void;
}

export default function TableFleetExecutionTask({
  fleet_execution_id,
  expandRows,
  handdleViewPopUp,
}: ITableFleetExecutionTaskProps) {
  const { executionTaskState, loading, errors } =
    useFleetExecutionsTask(fleet_execution_id);

  if (loading) return <TableLoad />;
  if (errors) return <p className="p-table-msg">Internal server error</p>;
  if (
    !executionTaskState ||
    (executionTaskState && executionTaskState.length === 0)
  )
    return <p className="p-table-msg">No data</p>;

  return (
    <>
      <HeaderFleetExecutionTask />
      {executionTaskState.executionTask.map((executionTask: any) => {
        return (
          <React.Fragment key={executionTask.fleet_execution_task_id}>
            <FleetExecutionTask
              expandRows={expandRows}
              executionTask={executionTask}
              fleet_execution_id={fleet_execution_id}
              handleViewPopUp={handdleViewPopUp}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}
