import React from "react";
import Depvar from "./Depvar";
import FleetExecutionTask from "../../TableFleetExecutions/TableFleetExecutionTask/FleetExecutionTask";
import CopyText from "../../Util/CopyText";

export interface ICleanDataProps {
  root_path: string;
  stacking_paths: any;
  ingestTask: any;
  dashboardsState: any;
  eventsState: any;
  handleViewPopUp:(value: boolean, data: any) => void;
}

export default function CleanData({ root_path, stacking_paths, ingestTask, dashboardsState, eventsState, handleViewPopUp}: ICleanDataProps) {

  const fleet_event_to_component = (e:any, ei:any) => {
    if(e.topic == "email") {
        return (<div className="fleet-event" key={ei+'email'}>[{e.event_time}] Client email: {e.details?.sent? "sent": "not sent"}</div>)
    } if(e.topic == "stability_metrics") {
      return null
    } else if(e.topic == "clean_data") {
      return (
        <div className="fleet-event" key={ei+'cd_issue'}>
          <div>[{e.event_time}] Clean data issues: </div>
          {
            typeof(e.details?.issues) === "object"?
            e.details?.issues?.map((issue:any, i:any) => (
              <div key={ei+'cd_issue'+i}><span> * ({issue.depvar + (issue.fatal?' FATAL':'')})</span> <span>{issue.description}</span></div>
            )): null
          }
        </div>
      );
    } else if(e.topic == "spikes") {
      return (
        <div className="fleet-event" key={ei+'spike_issue'}>
          <div>[{e.event_time}] Spike issues: </div>
          {
            typeof(e.details?.issues) === "object"?
            e.details?.issues?.map((issue:any, i:any) => (
              <div key={ei+'spike_issue'+i}><span> * {issue}</span></div>
            )): null
          }
        </div>
      );
    } else {
      return (<div className="fleet-event" key={e.topic + ei}>[{e.event_time}] {e.topic}: {JSON.stringify(e.details)}</div>)
    }
  }

  if (ingestTask.length < 1) {
    return (<div></div>)
  }

  //if (loading) return <TableLoad />;
  //if (errors) return <p className="p-table-msg">Internal server error</p>;
  let dashboards = []
  if (dashboardsState && dashboardsState.dashboards) {
    dashboards = dashboardsState.dashboards
  }

  const links = ingestTask?.map((executionTask: any) => executionTask.links)
  const paths = `paths = c(\n${stacking_paths.map((s:string) => `"${s}"`).join(",\n")}\n)`

  const cd_event = eventsState?.events?.filter((e:any) => e.topic === "clean_data")
  const cd_script = cd_event?.length > 0? cd_event[0].details?.code : null
  const spike_event = eventsState?.events?.filter((e:any) => e.topic === "spikes")
  const spike_script = spike_event?.length > 0? spike_event[0].details?.code : null
  const ingest_dash = dashboards.filter((d:any) => d.dashboard_type === 'data_validation' || d.dashboard_type === 'client_data_validation')

  return (
    <div className="container-fleets">
      {ingestTask.map((executionTask: any) => {
        return (
          <React.Fragment key={executionTask.fleet_execution_task_id}>
            <FleetExecutionTask
              expandRows={false}
              fleet_execution_id={executionTask.fleet_execution_id}
              executionTask={executionTask}
              handleViewPopUp={handleViewPopUp}
            />
          </React.Fragment>
        );
      })}

      <div className="ingestContainer">
        <div className="ingestCard">
          {
            ingest_dash.length > 0 ? (<h3>Ingest Dashboards</h3>) : null
          }
          {
            ingest_dash.map((d:any) =>
              (
                <p key={d.dashboard_id}>
                  <a rel="noreferrer" target={'_blank'} href={'/dashboard/'+d.dashboard_id}>{d.dashboard_type}</a>
                </p>
              )
            )
          }
        </div>
        <div className="ingestCard">
          <h3>Copy R Code</h3>
          { cd_script? (<CopyText title={"Clean data issues"} data={cd_script} />) : null }
          { spike_script? (<CopyText title={"Spikes issues"} data={spike_script} />) : null }
          <CopyText title={"Paths"} data={paths} />
        </div>
        <div className="ingestCard">
          { eventsState?.events?.length > 0 ? (<h3>Events</h3>) : null }
          <pre>{eventsState?.events?.map(fleet_event_to_component)}</pre>
        </div>
      </div>
    </div>
  )
}
