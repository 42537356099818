import axios from 'axios';
import getEncodedId from '../utils/getEncodedId';
import fetchAccessToken from '../utils/fetchAccessToken';

const getDashboards = async(path:string) => {
   const safepath = path.replaceAll("/", "!") // lambda crashes if we pass a normal url, even encoded
   const token = await fetchAccessToken();

   return axios.get(`${process.env.REACT_APP_URL}/v2/user/fleet_dashboards/root/${getEncodedId(safepath)}`,{
      headers: {
         'Content-Type' : 'application/json',
         'Authorization': `Bearer ${token}`
      },
      timeout: 60000
   })
}

export default getDashboards;
