import React from "react";
import TableLoad from "../../TableLoad";
import useWeeklyRefreshFleet from "../../../hooks/useWeeklyRefresh";
import useDashboards from "../../../hooks/useDashboards";
import useFleetEvents from "../../../hooks/useFleetEvents";
import Depvar from "./Depvar";
import CleanData from "./CleanData";
import Deploy from "./Deploy";

interface ITableFleetExecutionTaskProps {
  fleet_execution_id: string;
  expandRows?: boolean;
  handdleViewPopUp: (value: boolean, data: any) => void;
}

export default function TableWeeklyRefreshDepvar({
  fleet_execution_id,
  expandRows,
  handdleViewPopUp,
}: ITableFleetExecutionTaskProps) {
  const { fleetState, loading, errors } = useWeeklyRefreshFleet(fleet_execution_id);

  const stacking = fleetState?.depvars?.map((executionTask: any) => executionTask.machine_input.stacking)
  const depvar_names = fleetState?.depvars?.map((executionTask: any) => executionTask.machine_input.depvar)
  const { eventsState } = useFleetEvents(fleet_execution_id);
  let roots = eventsState?.fleetState?.depvars?.map((executionTask: any) => executionTask.machine_input.root)
  if (!roots || roots?.length === 0) {
    // if the ingest fails we will not have any task with a root_path input, so we have to grab it from the events
    roots = eventsState?.events?.filter((e:any) => e.topic === "clean_data")?.map((e:any) => e?.details?.root_path)
  }
  const root_path = (roots && roots?.length>0)?roots[0]:undefined
  const { dashboardsState } = useDashboards(root_path);

  if (loading) return <TableLoad />;
  if (errors) return <p className="p-table-msg">Internal server error</p>;
  if (!fleetState || !fleetState.ingest) {
    return <p className="p-table-msg">No data</p>;
  }

  return (
    <>
      <CleanData
        root_path={root_path}
        stacking_paths={stacking}
        ingestTask={fleetState.ingest}
        dashboardsState={dashboardsState}
        handleViewPopUp={handdleViewPopUp}
        eventsState={eventsState}
      />
      <Deploy
        root_path={root_path}
        depvars={depvar_names}
        deployTask={fleetState.deploy}
        dashboardsState={dashboardsState}
        handleViewPopUp={handdleViewPopUp}
        eventsState={eventsState}
      />
      {fleetState.depvars?.map((executionTask: any) => {
        return (
          <React.Fragment key={executionTask.fleet_execution_task_id}>
            <Depvar
              executionTask={executionTask}
              handleViewPopUp={handdleViewPopUp}
              dashboardsState={dashboardsState}
              eventsState={eventsState}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}
