import { useDispatch, useSelector } from 'react-redux'
import { addFilter } from '../redux/executionsSlice';



export default function useHeaderFleetExecutions(){
    const filters = useSelector((state: any) => state.executions.filters)
    const dispatch = useDispatch()
    const handdleFilters = (key: string, value: string) => {
        const order = 'order'
        dispatch(addFilter({...filters, [order]:{[key]:value}}))
    }
    return {handdleFilters, filters}
}
