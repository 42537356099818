import React from 'react';

interface Props {
  letters: string;
  size?: 'small' | 'large';
}

const LettersIcon: React.FC<Props> = ({letters, size = 'small'}) => {

  return (
    <div className={`letters-icon ${size}`}>
      {letters}
    </div>
  );
};

export default LettersIcon;
