import DashboardIcon from '../icons/grid_icon'
import TableFleetExecutions from '../TableFleetExecutions'
import { useParams } from "react-router-dom";

export default function FleetExecutions(){
    const params = useParams();
    return(
        <section className='content'>
            <div className='first-row'>
                <div className='container-icon'>
                    <DashboardIcon width='17px' height='17px' fill='white' />
                </div>
                <h1>FleetExecutions</h1>
            </div>
            <div className='table-container'>
                <TableFleetExecutions fleet_execution_id={params.fleet_execution_id} />
            </div>
        </section>
    )
}
