//Types
import {IconType} from './inconTypes'

//Component
const DashboardIcon = ({width, height, fill}: IconType):JSX.Element => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height={height} viewBox="0 0 24 24" width={width} fill={fill}>
            <g>
                <rect fill="none" height="24" width="24"/>
            </g>
            <g>
                <g>
                    <g>
                        <path d="M3,3v8h8V3H3z M9,9H5V5h4V9z M3,13v8h8v-8H3z M9,19H5v-4h4V19z M13,3v8h8V3H13z M19,9h-4V5h4V9z M13,13v8h8v-8H13z M19,19h-4v-4h4V19z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

//Export
export default DashboardIcon



