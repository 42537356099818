//Types
import { IconType } from "./inconTypes";

//Component
const IngestIcon = ({ width, height, fill }: IconType): JSX.Element => {
  return (
    <svg width={width} height={height} fill={fill} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="layer1">
        <path d="M 8 1 L 8 5 L 9 5 L 9 2 L 19 2 L 19 19 L 9 19 L 9 16 L 8 16 L 8 20 L 20 20 L 20 1 L 8 1 z M 10 7 L 13 10 L 0 10 L 0 11 L 13 11 L 10 14 L 11.5 14 L 15 10.5 L 11.5 7 L 10 7 z "/>
      </g>
    </svg>
  );
};

//Export
export default IngestIcon;
