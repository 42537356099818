import { configureStore } from '@reduxjs/toolkit'
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
} from "react-redux";
import fleetExecutionsReducer from "./executionsSlice"
import fleetExecutionTaskReducer from "./executionTasksSlice"
import weeklyRefreshReducer from "./weeklyRefreshSlice"
import clientsReducer from "./clientsSlice"
import configSlice from './configSlice'

export const store = configureStore({
  reducer: {
    executions: fleetExecutionsReducer,
    executionTask: fleetExecutionTaskReducer,
    weeklyRefresh: weeklyRefreshReducer,
    clients: clientsReducer,
    config: configSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
