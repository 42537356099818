import '../../scss/SessionLoader.scss';
import { useEffect, useState, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { Loader } from '@aws-amplify/ui-react';
import UserContext from '../../context/UserContext';


export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const {setUser} = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();

  useEffect(() => {
    async function checkAuthState() {
      try {
        await getCurrentUser();
        await fetchAuthSession();
        setIsAuthenticated(true);
      } catch (error) {
        setUser(null);
        setIsAuthenticated(false);
      }
    }

    checkAuthState();
  }, [location]);

  if (isAuthenticated === null) {
    return (
      <div className='session-loader'>
        <Loader size='large' />
        <div>Loading</div>
      </div>
    );
    
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
}