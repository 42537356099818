import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addEvent } from "../redux/weeklyRefreshSlice"
import getFleetEvents from '../services/getFleetEvents'

export default function useFleetEvents(fleet_execution_id: string){
  
  const [eventsState, setEventsState] = useState<any>(null)
  const [errors, setErrors] = useState<boolean|null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [viewEvents, setViewEvents] = useState(false)

  let weeklyRefresh: any = useSelector((state: any) => state.weeklyRefresh)
  
  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)    
    const min_age = Date.now() - 60 * 1000 // 60 seconds
    const found:any = weeklyRefresh?.events[fleet_execution_id];
    if (!found || found.time_added < min_age) {
      getFleetEvents(fleet_execution_id)
        .then(response => {
          if(response.status === 200){  
            const sorted_events = response.data.events.sort((e1:any, e2:any) => {
              return e2.event_time.localeCompare(e1.event_time)
            })
            const event_data = {[fleet_execution_id]: {events: sorted_events, time_added: Date.now()}};
            dispatch(addEvent(event_data));
            setEventsState(event_data)
          }
        })
        .catch((error) => {
          setErrors(true)
          console.log(error)
        })
        .finally(() => setLoading(false))
    } else {
      setEventsState(found)
      setLoading(false)
    }
  },[fleet_execution_id, dispatch, weeklyRefresh])

  const handleView = () => {
    setViewEvents(!viewEvents)
  }

  return{eventsState, errors, loading, viewEvents, handleView}
}
