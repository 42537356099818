import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addDashboard } from "../redux/weeklyRefreshSlice"
import getDashboards from '../services/getDashboards'

export default function useDashboards(s3_root_path: string){
  
  const [dashboardsState, setDashboardsState] = useState<any>(null)
  const [errors, setErrors] = useState<boolean|null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [viewDashboards, setViewDashboards] = useState(false)

  let weeklyRefresh: any = useSelector((state: any) => state.weeklyRefresh)
  
  const dispatch = useDispatch()

  const found:any = weeklyRefresh?.dashboards[s3_root_path];

  useEffect(() => {
    if(!s3_root_path) {
      return
    }
    setLoading(true)    
    const min_age = Date.now() - 60 * 1000 // 60 seconds
    if (!found || found.time_added < min_age) {
      getDashboards(s3_root_path)
        .then(response => {
          if(response.status === 200){  
            const dash_data = {[s3_root_path]: {dashboards: response.data.dashboards, time_added: Date.now()}};
            dispatch(addDashboard(dash_data));
            setDashboardsState(dash_data)
          }
        })
        .catch((error) => {
          setErrors(true)
          console.log(error)
        })
        .finally(() => setLoading(false))
    } else {
      setDashboardsState(found)
      setLoading(false)
    }
  },[s3_root_path, dispatch, found])

  const handleView = () => {
    setViewDashboards(!viewDashboards)
  }

  return{dashboardsState, errors, loading, viewDashboards, handleView}
}
