//Types
import {IconType} from './inconTypes'

//Component
const LogIcon = ({width, height, fill}: IconType):JSX.Element => {

    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 1000 900">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <path d="M879,360.5h-23.5V247c0-0.7-0.1-1.4-0.2-2.1c0-4.5-1.5-8.9-4.6-12.4L661.8,16.6c-0.1-0.1-0.1-0.1-0.2-0.1c-1.1-1.3-2.4-2.3-3.8-3.2c-0.4-0.3-0.8-0.5-1.3-0.8c-1.2-0.7-2.5-1.2-3.8-1.6c-0.4-0.1-0.7-0.2-1-0.3c-1.4-0.3-2.9-0.6-4.4-0.6H182.9c-21.2,0-38.4,17.2-38.4,38.4v312.1H121c-30.3,0-54.9,24.6-54.9,54.9v285.6c0,30.3,24.6,54.9,54.9,54.9h23.5v195.5c0,21.2,17.2,38.4,38.4,38.4h634.1c21.2,0,38.4-17.2,38.4-38.4V756H879c30.3,0,54.9-24.6,54.9-54.9V415.5C933.9,385.1,909.3,360.5,879,360.5z M182.9,48.4h445.2v196.6c0,10.6,8.6,19.2,19.2,19.2h169.8v96.3H182.9V48.4z M578.1,552.3c0,85.4-51.8,137.2-127.9,137.2c-77.3,0-122.5-58.3-122.5-132.5c0-78.1,49.8-136.4,126.7-136.4C534.4,420.6,578.1,480.5,578.1,552.3z M156.8,685.3V424.8H216v211h103.6v49.4L156.8,685.3L156.8,685.3z M817.1,941.2H182.9V756h634.1L817.1,941.2L817.1,941.2z M834.3,673.3c-18.2,6.2-52.6,14.7-86.9,14.7c-47.5,0-81.9-12-105.9-35.1c-23.9-22.4-37.1-56.4-36.7-94.7c0.4-86.6,63.4-136,148.8-136c33.6,0,59.5,6.6,72.3,12.8L813.4,482c-14.3-6.2-32.1-11.2-60.7-11.2c-49.1,0-86.2,27.8-86.2,84.2c0,53.7,33.6,85.4,81.9,85.4c13.5,0,24.3-1.5,29-3.8v-54.5h-40.2v-46h97L834.3,673.3L834.3,673.3z"/><path d="M389.9,555.8c0,51,24,86.9,63.4,86.9c39.8,0,62.6-37.9,62.6-88.5c0-46.7-22.4-87-63-87C413.1,467.3,389.9,505.2,389.9,555.8z"/>
        </svg>
    )
}

//Export
export default LogIcon

