import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setViewTerminate } from "../redux/executionTasksSlice"
import deleteFleetExecution from '../services/deleteFleetExecution'
import deleteFleetExecutionTask from '../services/deleteFleetExecutionTask'

export default function useModalAbort(){
    const [text, setText] = useState('')
    const [loading, setLoading] = useState<Boolean>(false)
    const dataTerminate = useSelector((state: any) => {
        if(state.executions.canViewPopUp) {
            return state.executions.dataTerminate;
        } else {
            return state.executionTask.dataTerminate;
        }
    })
    const dispatch = useDispatch()

    const cancelMd = () => {
        dispatch(setViewTerminate({view: false, data: null}))
    }

    const confirmMd = () => {
        setLoading(true)
        const del_fun = dataTerminate.primary_task ? deleteFleetExecution : deleteFleetExecutionTask;
        del_fun(dataTerminate.fleet_execution_id, dataTerminate.fleet_execution_task_id)
        .then(
            response =>{
                if(response.status === 200){
                    console.log(response.data)
                }
                dispatch(setViewTerminate({view: false, data: null}))
            }
        )
        .catch(error => {
            if(error.response.status === 401){
                console.log("error 401", error)
            }
            dispatch(setViewTerminate({view: false, data: null}))
        })
        .finally(() => setLoading(false))
    }

    return {cancelMd, confirmMd, dataTerminate, text, setText, loading}
}
