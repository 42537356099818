import { createSlice, current } from '@reduxjs/toolkit'

function sortList(filters, list) {
  let order = list.clients

  if(filters?.client) {
    if(filters.client.exclude) {
      delete order[filters.client.filter]
    } else {
      order = {[filters.client.filter]: order[filters.client.filter]}
    }
  }

  return order
}

function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export const clientsSlice = createSlice({
  name: "clients",
  initialState: {
    jobSizes: {},
    jobSizesFiltered: {},
    filters: {},
    viewEditPopUp: false,
    dataEdit: null
  },
  reducers: {
    addJobSize: (state, action) => {
      const data = action.payload
      const s = current(state.jobSizes)
      const js = {
        ...s,
        clients: {
          ...s.clients,
          [data.client]: {
            ...s.clients[data.client],
            [data.run_type]: data
          }
        }
      }
      state.jobSizes = js
      state.jobSizesFiltered = sortList(state.filters, js)
    },
    addJobSizes: (state, action) => {
      state.jobSizes = {...state.jobSizes, ...action.payload}
      state.jobSizesFiltered = sortList(state.filters, state.jobSizes)
    },
    addFilter: (state, action) => {
      const filters = action.payload
      state.filters = filters
      let list = state.jobSizes
      state.jobSizesFiltered = sortList(filters, current(list))
    },
    setViewEdit: (state, action) => {
      state.viewEditPopUp = action.payload.view
      if(!action.payload.data) {
        state.dataEdit = null
      } else {
        const js = removeEmpty(action.payload.data.jobSizes)
        const def = current(state.jobSizes.default)
        const data = {
          codename: action.payload.data.codename,
          run_type: action.payload.data.run_type,
          jobSizes: { ...def, ...js }
        }
        state.dataEdit = data
      }
    },
  },
});

export const { addJobSize, addJobSizes, setViewEdit, addFilter } = clientsSlice.actions
export default clientsSlice.reducer
