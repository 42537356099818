import TableRows, { IFleetExecution } from './TableRows'
import useView from '../../hooks/useView'
import { useCallback, useEffect } from 'react'
import TableWeeklyRefreshDepvar from './WeeklyRefreshDepvar'
import Status from './Status'

export interface IFleetExecutionProps {
    execution: IFleetExecution;
    expandRows?: boolean;
    handdleViewPopUp: (value: boolean, data: any) => void;
}

export default function FleetExecution({ execution,  expandRows,handdleViewPopUp }: IFleetExecutionProps){

    const {view, handdleView} = useView()
    const changeView = useCallback(() => {
        handdleView()
    }, [handdleView])

    useEffect(() => {
        if(expandRows){
            changeView()
        }
    },[ changeView, expandRows])

    const ds_status = (<Status execution={execution} />)

    return(
        <div className="job-element" key={execution.fleet_execution_id}>
                <div className="tableJobs header-weekly-refresh" onClick={() => {handdleView()}}>
                  <TableRows
                    fleet_execution_id={execution.fleet_execution_id}
                    aws_username={execution.aws_username}
                    client={execution.client}
                    end_date={execution.end_date}
                    start_date={execution.start_date}
                    links={execution.links}
                    tags={execution.tags}
                    machine_name={execution.machine_name}
                    status={execution.status}
                    task_status={ds_status}
                    handleViewPopUp={handdleViewPopUp}
                  />
                </div>
                {view && <TableWeeklyRefreshDepvar expandRows={expandRows} fleet_execution_id={execution.fleet_execution_id} handdleViewPopUp={handdleViewPopUp}/>}
            </div>
    )
}
