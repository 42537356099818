import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addJobSizes } from "../redux/clientsSlice"
import getJobSizes from '../services/getJobSizes'

export default function useJobSizes(){
  
  const [sizesState, setSizesState] = useState<any>(null)
  const [errors, setErrors] = useState<boolean|null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState<boolean>(false);
  const [viewSizes, setViewSizes] = useState(false)

  let jobSizes: any = useSelector((state: any) => state.clients.jobSizes)
  let filtered: any = useSelector((state: any) => state.clients.jobSizesFiltered)
  
  const dispatch = useDispatch()
  const found:any = refresh?null:jobSizes

  const handdleRefresh = useCallback(() => {
    setRefresh(rfresh => !rfresh)
  },[dispatch]);

  useEffect(() => {
    setRefresh(false)
    setLoading(true)    
    const min_age = Date.now() - 3 * 60 * 60 * 1000 // 3 hour
    console.log(found?.time_added)
    if (!found || !found.time_added || found.time_added < min_age) {
      getJobSizes()
        .then(response => {
          if(response.status === 200){  
            const sizes_data = { ...response.data, time_added: Date.now()};
            dispatch(addJobSizes(sizes_data));
            setSizesState(sizes_data)
          }
        })
        .catch((error) => {
          setErrors(true)
          console.log(error)
        })
        .finally(() => setLoading(false))
    } else {
      setSizesState(found)
      setLoading(false)
    }
  },[refresh, dispatch, found])

  const handleView = () => {
    setViewSizes(!viewSizes)
  }

  const viewEditPopUp = useSelector((state: any) => {
    return state.clients.viewEditPopUp
  });

  const refreshTables = () => {
    dispatch(addJobSizes(null))
    handdleRefresh()
  }

  return{sizesState, errors, loading, viewSizes, viewEditPopUp, handleView, tJobSizes: filtered, refreshTables}
}
