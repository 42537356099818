//Types
import {IconType} from './inconTypes'

//Component
const RecastSmallLogo = ({width, height, fill}: IconType):JSX.Element => {
    return(
        <svg width={width} height={height} viewBox="0 0 116 117" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M116 58.3141C116 90.1889 90.1605 116.028 58.2857 116.028C43.4979 116.028 30.0091 110.467 19.7969 101.321C35.6122 91.8881 41.343 86.7777 53.5339 74.0543C73.8753 52.8243 82.845 23.6553 85.8853 7.61415C103.827 17.402 116 36.4365 116 58.3141ZM79.9434 4.80086C76.8905 3.56406 73.7076 2.58093 70.421 1.87762C67.2671 16.7361 57.128 39.5997 43.1861 51.0782C30.9164 61.1799 17.0143 67.8776 2.10467 71.5865C3.14687 76.0149 4.69915 80.2466 6.69609 84.2162C20.1262 81.5499 27.0185 77.3746 39.6816 68.0472C63.7373 50.3282 77.2988 18.569 79.9434 4.80086ZM8.92122 58.7022C6.14817 59.2463 3.38681 59.7882 0.610847 60.4726C0.584511 59.7563 0.571228 59.0368 0.571228 58.3141C0.571228 26.4392 26.4109 0.599609 58.2857 0.599609C61.7712 0.599609 65.1846 0.908588 68.4999 1.50065C65.8817 14.1991 53.3322 36.2672 40.4585 46.0478C28.9839 54.7654 18.8772 56.7486 8.92122 58.7022Z" fill="#E27C66"/>
        </svg>
    )
}

export default RecastSmallLogo
