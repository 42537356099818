import axios from 'axios'
import fetchAccessToken from '../utils/fetchAccessToken';

const getFleetExecutions = async() => {
  const token = await fetchAccessToken();

  return axios.get(`${process.env.REACT_APP_URL}/v2/user/fleet_executions`,{
    headers: {
      'Content-Type' : 'application/json',
      'Authorization': `Bearer ${token}`
    },
    timeout: 60000
  })
}

export default getFleetExecutions
