function orderedList(list:any, value: boolean, parameter: string){
    const newList =list.slice().sort((el1:any, el2:any) => {
        if(value) {
            return el1[parameter].toString().toLowerCase().localeCompare(el2[parameter].toString().toLowerCase())
        }
        else{
            return el2[parameter].toString().toLowerCase().localeCompare(el1[parameter].toString().toLowerCase())
        }
    })
    return newList
}

const sortedFleetExecutionTasks = (list:any, filters: any) => {
    let order = list
    if(filters?.order?.end_date){
        let value: boolean = true
        filters.order.end_date === 'growing'? value = true : value = false
        order = orderedList(list, value, "end_date")
    }
    if(filters?.order?.start_date){
        let value: boolean = true
        filters.order.start_date === 'growing'? value = true : value = false
        order = orderedList(list, value, "start_date")
    }
    if(filters?.order?.machine_name){
        let value: boolean = true
        filters.order.machine_name === 'growing'? value = true : value = false
        order = orderedList(list, value, "machine_name")
    }
    if(filters?.order?.fleet_execution_task_id){
        let value: boolean = true
        filters.order.fleet_execution_task_id === 'growing'? value = true : value = false
        order = orderedList(list, value, "fleet_execution_task_id")
    }
    if(filters?.order?.status){
        let value: boolean = true
        filters.order.status === 'growing'? value = true : value = false
        order = orderedList(list, value, "status")
    }
    return order
}

export default sortedFleetExecutionTasks
