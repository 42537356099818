import { useDispatch } from "react-redux";
import { setViewTerminate } from "../redux/executionTasksSlice";
import { ILink } from "./useTableRowsFleetExecution";


export default function useTableRowsFleetExecutionTask(
  fleet_execution_id: string,
  fleet_execution_task_id: string,
  machine_name: string,
  status: string,
  start_date: string,
  end_date: string,
  primary_task: boolean,
  links: ILink[],
  machine_input: any,
  handdleViewPopUp: any
) {
  const dispatch = useDispatch();
  const handdleView = (e: React.MouseEvent) => {
    e.stopPropagation();
    const executionTask = {
      fleet_execution_id,
      fleet_execution_task_id,
      status,
      start_date,
      end_date,
      machine_name,
      links,
      primary_task,
      machine_input
    };
    handdleViewPopUp(true, executionTask);
  };
  const handdleCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setViewTerminate({
        view: true,
        data: {
          fleet_execution_task_id,
          fleet_execution_id,
          start_date,
          machine_name,
          status,
          primary_task
        },
      })
    );
  };
  return { handdleView, handdleCancel };
}
