import { ILink } from "../../../hooks/useFleetExecutions";
import TableRows from "./TableRows";


export interface IFleetExecutionTask {
  fleet_execution_id: string;
  fleet_execution_task_id: string;
  machine_name: string;
  status: string;
  start_date: string;
  end_date: string;
  primary_task: boolean;
  links: ILink[];
  machine_input: any;
  handleViewPopUp:(value: boolean, data: any) => void
}

export interface IFleetExecutionProps {
  executionTask: IFleetExecutionTask;
  fleet_execution_id: string;
  expandRows?: boolean;
  handleViewPopUp:(value: boolean, data: any) => void
}

export default function FleetExecutionTask({ executionTask, handleViewPopUp}: IFleetExecutionProps) {
  return (
    <div className="container-fleets">
      <div className="tableFleets header-task">
        <TableRows
          fleet_execution_id={executionTask.fleet_execution_id}
          fleet_execution_task_id={executionTask.fleet_execution_task_id}
          machine_name={executionTask.machine_name}
          status={executionTask.status}
          start_date={executionTask.start_date}
          end_date={executionTask.end_date}
          links = {executionTask.links}
          primary_task = {executionTask.primary_task}
          machine_input = {executionTask.machine_input}
          handleViewPopUp={handleViewPopUp}
        />
      </div>
    </div>
  )
}
