import { VegaLite, VisualizationSpec } from 'react-vega'

export default function IngestSchedule(schedule:any) {
  if (!schedule?.schedule) {
    return null
  }

  const today:Date = new Date(Date.now())
  const cur_month = today.getUTCMonth()
  const cur_year = today.getUTCFullYear()
  const cur_day = today.getUTCDay()
  const cur_date = today.getUTCDate()
  const cur_week_start = new Date(Date.now() - cur_day * 24 * 3600 * 1000)
  const cur_week_date = cur_week_start.getUTCDate()
  const spec:VisualizationSpec = {
    data: { name: "schedule" },
    transform: [
      {calculate: `datetime(${cur_year}, ${cur_month}, ${cur_week_date}+datum.day, datum.hour, 0, 0, 0)`, as: "time"},
      {calculate: `datetime(${cur_year}, ${cur_month}, ${cur_date}, ${today.getUTCHours()}, ${today.getUTCMinutes()}, 0, 0)`, as: "now"},
      {calculate: "dayFormat(datum.day)", as: "wday"}
    ],
    hconcat: [
      {
        mark: "rect",
        width: 400,
        height: 75,
        encoding: {
          x: {
            bin: {maxbins:24},
            field: "hour",
            type: "quantitative",
            axis: { labelExpr: "round(datum.value)" },
            title: "Launch time (UTC)"
          },
          y: {
            field: "wday",
            type: "nominal",
            sort: {field: "day"},
            title: "Launch day"
          },
          color: {
            aggregate: "count",
            type: "quantitative",
            legend: {title: "Count" }
          }
        },
      },
      {
        width: 300,
        height: 75,
        layer: [
          {
            mark: {
              type: "rule"
            },
            encoding: {
              y: {
                field: "now",
                type: "temporal"
              }
            }
          },
          {
            mark: {
              type: "circle",
              size: 50
            },
            transform: [
              { filter: `abs(datum.day - ${cur_day}) < 2` },
              { calculate: `format(time(datum.time)/3600000 - time(datum.now)/3600000, '.1f')`, as: 'launching-in'}
            ],
            encoding: {
              tooltip: [{ field: "name"}, {field: "time", type: 'temporal', format: "%H:%M, %b %d"}, {field: "launching-in", type: 'nominal'} ],
              y: {
                field: "time",
                type: "temporal",
                title: "3-day launch"
              },
              x: {
                field: "name",
                type: "nominal",
                sort: {field: "time"},
                axis: {
                  labelAngle: -45,
                  title: null
                }
              },
            },
          },
        ]
      }
    ],
    config: {
      view: {
        stroke: "transparent"
      }
    }
  }

  const data = {schedule: schedule?.schedule?.map((d:any) => {return { name: d.name, hour: d.hour, day:d.day }}) }
  return (<VegaLite data={data} spec={spec} actions={false} renderer={"svg"} />)
};
