import axios from 'axios';
import fetchAccessToken from '../utils/fetchAccessToken';

const getIngestSchedule = async() => {
   const token = await fetchAccessToken();

   return axios.get(`${process.env.REACT_APP_URL}/v2/user/ingest_schedule`,{
      headers: {
         'Content-Type' : 'application/json',
         'Authorization': `Bearer ${token}`
      },
      timeout: 60000
   })
}

export default getIngestSchedule;
