import ArrowDropUp from '../icons/arrow_drop_up_icon'
import ArrowDropDown from '../icons/arrow_drop_down_icon'
import useHeaderFleetExecutions from '../../hooks/useHeaderFleetExecutions'

export default function HeaderFleetExecutionsTable():JSX.Element{

    const {handdleFilters, filters} = useHeaderFleetExecutions()

    return(
        <div className='headerAll header-weekly-refresh'>
            <div>
                <p>Client</p>
                <div>
                    <span onClick={() => handdleFilters("client", "growing")} 
                        className={filters?.order?.client === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("client", "decreasing")}
                        className={filters?.order?.client === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>Status</p>
                <div>
                    <span onClick={() => handdleFilters("status", "growing")} 
                        className={filters?.order?.status === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("status", "decreasing")}
                        className={filters?.order?.status === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>Start Date</p>
                <div>
                    <span onClick={() => handdleFilters("start_date", "growing")} 
                        className={filters?.order?.start_date === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("start_date", "decreasing")}
                        className={filters?.order?.start_date === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>End Date</p>
                <div>
                    <span onClick={() => handdleFilters("end_date", "growing")} 
                        className={filters?.order?.end_date === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("end_date", "decreasing")}
                        className={filters?.order?.end_date === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>Tags</p>
            </div>
        </div>
    )
}
