import { Amplify } from 'aws-amplify';

const configureAmplify = (): void => {
    const redirectSignInUrls = `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN_URL}`.split(',');
    const redirectSignOutUrls = `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT_URL}`.split(',');
    const scopes = `${process.env.REACT_APP_COGNITO_SCOPES}`.split(',');

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`,
                userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
                loginWith: {
                    oauth: {
                        domain: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
                        scopes: scopes,
                        redirectSignIn: redirectSignInUrls,
                        redirectSignOut: redirectSignOutUrls,
                        responseType: `${process.env.REACT_APP_COGNITO_RESPONSE_TYPE}` as 'code' | 'token',
                    }
                }
            }
        }
    })
};

export default configureAmplify;
