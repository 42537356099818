import ExecutionFilter from "../TableFleetExecutions/Filter/ExecutionFilter";

export default function FilterWeeklyRefresh(){

    return(
        <div className='filters-jobs'>
            <ExecutionFilter objKey='client' name='Client' />
            <ExecutionFilter objKey='status' name='Status' />
            <ExecutionFilter objKey='tags' name='Tags' />
        </div>
    )
}
