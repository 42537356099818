import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addIngestSchedule } from "../redux/weeklyRefreshSlice"
import getIngestSchedule from '../services/getIngestSchedule'

export default function useIngestSchedule(){
  
  const [scheduleState, setScheduleState] = useState<any>(null)
  const [errors, setErrors] = useState<boolean|null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [viewSchedule, setViewSchedule] = useState(false)

  let weeklyRefresh: any = useSelector((state: any) => state.weeklyRefresh)
  
  const dispatch = useDispatch()

  const found:any = weeklyRefresh?.schedule;

  useEffect(() => {
    setLoading(true)
    const min_age = Date.now() - 3 * 60 * 60 * 1000 // 3 hour
    console.log(found?.time_added)
    if (!found || !found.time_added || found.time_added < min_age) {
      getIngestSchedule()
        .then(response => {
          if(response.status === 200){  
            const schedule_data = {schedule: response.data, time_added: Date.now()};
            dispatch(addIngestSchedule(schedule_data));
            setScheduleState(schedule_data)
          }
        })
        .catch((error) => {
          setErrors(true)
          console.log(error)
        })
        .finally(() => setLoading(false))
    } else {
      setScheduleState(found)
      setLoading(false)
    }
  },[dispatch, found])

  const handleView = () => {
    setViewSchedule(!viewSchedule)
  }

  return{scheduleState, errors, loading, viewSchedule, handleView}
}
