import LettersIcon from "../icons/letters_icon";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import getInitials from "../../utils/getInitials";



export default function UserSection():JSX.Element{
    const user = useContext(UserContext).user
    const fullName = user?.fullName; 
    const letters = getInitials(fullName)
    const email = user?.email

    return(
        <div className='user-information'>
            <div className='img-container'>
                <LettersIcon letters={letters} size='small'/>
                <span className='user-status'></span>
            </div>
            <div className='user-data'>
                <p className='user-data-name'>{fullName}</p>
                <p className='user-data-email'>{email}</p>
            </div>
        </div>
    )
}
