import CloseIcon from '../icons/close_icon'

export default function ModalFleetsExecutionTask({viewPopUp, handdleViewPopUp}:any){

    const handdleClose = () => {
        handdleViewPopUp(false, null)
    }

    return(
        <div className='modal-container'>
            <section>
                <div className='header'>
                    <h1>Selected Fleet Execution Task</h1>
                    <span onClick={handdleClose}>
                        <CloseIcon width='20px' height='20px' />
                    </span>
                </div>
                <div className='body-section'>
                    <div className='section-item'>
                        <div className='title'>
                            <h2>Fleet Execution ID</h2>
                        </div>
                        <p>{viewPopUp.data.fleet_execution_id}</p>
                    </div>
                    <div className='section-item'>
                        <div className='title'>
                            <h2>Status</h2>
                        </div>
                        <div className={`${viewPopUp.data.status.toLowerCase()} status`}>
                            <p>{viewPopUp.data.status}</p>
                        </div>
                    </div>
                    <div className='section-item-double'>
                        <div className='title'>
                            <h3>Start Date</h3>
                        </div>
                        <div className='title'>
                            <h3>End Date</h3>
                        </div>
                        <div>
                            <p>{viewPopUp.data.start_date}</p>
                        </div>
                        <div>
                            <p>{viewPopUp.data.end_date}</p>
                        </div>
                    </div>
                    {
                        viewPopUp.data.links.map((element:any, index: number) => {
                            return(
                                <div key={index} className='section-item'>
                                    <div className='title'>
                                        <h2>Link {element.label}</h2>
                                    </div>
                                    <a href={element.url} target="_blank" rel="noreferrer" className={`img-link-cont ${element.label.toLowerCase()}`}>
                                        {element.label}
                                    </a>
                                </div>
                            )
                        })
                    }
                    <div className='section-item'>
                        <div className='title'>
                            <h2>Input</h2>
                        </div>
                        <pre>{JSON.stringify(viewPopUp.data.machine_input, null, 2) + "\n\n"}</pre>
                    </div>
                </div>
            </section>
        </div>
    )
}
