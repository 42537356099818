import MenuIcon from '../icons/menu_icon'
import MenuCloseIcon from '../icons/menu_close_icon'
import { useSelector, useDispatch } from 'react-redux'
import { updateSize } from '../../redux/configSlice'

export default function MenuButton():JSX.Element{

    const configSize = useSelector((state: any) => state.config.size)
    const dispatch = useDispatch()
    
    return(
        <button onClick={() => dispatch(updateSize(configSize === 'bigMain' ? 'smallMain' : 'bigMain'))}>
            <span className='hidden'>menu</span>
            {
                configSize !== 'bigMain'?
                    <MenuIcon width='22px' height='22px' />:
                    <MenuCloseIcon width='24px' height='24px' />
            }
        </button>
    )
}
