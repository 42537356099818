import { useState } from 'react'
import { useSelector } from 'react-redux'

export default function useFilterDataClients(){
  const [view, setView] = useState<boolean>(false)
  const jobSizes = useSelector((state: any) => state.clients.jobSizes)

  let arrayAux:string[] = jobSizes && jobSizes.clients? Object.keys(jobSizes.clients).sort() : []

  return{view, setView, arrayAux}
}
