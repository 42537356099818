import ArrowDropUp from '../../icons/arrow_drop_up_icon'
import ArrowDropDown from '../../icons/arrow_drop_down_icon'
import useHeaderFleetExecutionsTasks from '../../../hooks/useHeaderFleetExecutionsTasks'


export default function HeaderFleetExecutionTask(){

    const {handdleFilters, filters} = useHeaderFleetExecutionsTasks()

    return(
        <div className='headerAllFleet header-task'>
               <div>
                <p>Task Type</p>
                <div>
                    <span onClick={() => handdleFilters("machine_name", "growing")} 
                        className={filters?.order?.machine_name === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("machine_name", "decreasing")}
                        className={filters?.order?.machine_name === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>Status</p>
                <div>
                    <span onClick={() => handdleFilters("status", "growing")} 
                        className={filters?.order?.status === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("status", "decreasing")}
                        className={filters?.order?.status === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>Start Date</p>
                <div>
                    <span onClick={() => handdleFilters("start_date", "growing")} 
                        className={filters?.order?.start_date === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("start_date", "decreasing")}
                        className={filters?.order?.start_date === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>End Date</p>
                <div>
                    <span onClick={() => handdleFilters("end_date", "growing")} 
                        className={filters?.order?.end_date === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("end_date", "decreasing")}
                        className={filters?.order?.end_date === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>Links</p>
            </div>
            <div>
                <p>FleetExecution Task ID</p>
                <div>
                    <span onClick={() => handdleFilters("fleet_execution_task_id", "growing")} 
                        className={filters?.order?.fleet_execution_task_id === "growing"? "selectedFilter": ""}>
                        <ArrowDropUp width='18px' height='18px' />
                    </span>
                    <span onClick={() => handdleFilters("fleet_execution_task_id", "decreasing")}
                        className={filters?.order?.fleet_execution_task_id === "decreasing"? "selectedFilter": ""}>
                        <ArrowDropDown width='18px' height='18px' />
                    </span>
                </div>
            </div>
            <div>
                <p>Actions</p>
            </div>
        </div>
    )
}
