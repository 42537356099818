import { useState } from 'react'

export default function HoverText({text, children}:any) {
  if(!text || text.length < 1) {
  return (<>{children}</>);
  }
  return(
    <>
      <div className="tooltip">
        {children}
        <div>{text.map((e:any) => (<div className="tooltip-text">{e}</div>))}</div>
      </div>
    </>
   )
}
