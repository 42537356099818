import { createSlice } from '@reduxjs/toolkit'

export const executionTasksSlice = createSlice({
  name: "executionTask",
  initialState: {
    executionTask: {},
    executionTaskFilters: [],
    filters: { order: {fleet_execution_task_id: "growing"} },
    dataTerminate: null
  },
  reducers: {
    addFleetExecutionTask: (state, action) => {
      state.executionTask = {...state.executionTask, ...action.payload}
      state.executionTaskFilters = action.payload
    },
    addFilter: (state, action) => {
      const filters = action.payload
      state.filters = filters
    },
    setViewTerminate: (state, action) => {
      state.viewCancelPopUp = action.payload.view
      state.dataTerminate = action.payload.data
    }
  },
});

export const { addFleetExecutionTask, addFilter, setViewTerminate } = executionTasksSlice.actions
export default executionTasksSlice.reducer
