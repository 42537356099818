import './scss/NotFound.scss'
import { useNavigate } from 'react-router-dom'

export default function NotFound(): JSX.Element{

    const navigate = useNavigate()
    
    return(
            <div className='notfound'>
                <div className='notfound-404'>
                    <h1>Oops!</h1>
                </div>
                <h2>404 - Not Found</h2>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <button onClick={() => navigate('/')}>
                    Go To Homepage
                </button>
            </div>     
    )
}
