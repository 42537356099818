import ArrowDropUp from '../icons/arrow_drop_up_icon'
import ArrowDropDown from '../icons/arrow_drop_down_icon'

export default function HeaderJobSize():JSX.Element{
  const cols = ['Model Runs', 'Stacking', 'Backtesting', 'Dashboard Build']

  return(
    <div className='headerAll header-client-job-size'>
      <div>
        <p>Client</p>
      </div>
      {
      cols.map((j) => (
      <div key={'hcol'+j}>
        <p>{j}</p>
      </div>
      ))}
    </div>
  )
}
