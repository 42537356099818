import Select from 'react-select'
import { useRef, useState } from 'react'


interface FilterParams{
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
  arrayAux: string[];
  deleteFilter: (filterType: string) => void;
  handdleFilters: (filterStrig: string, filterType:string, exclude?:boolean) => void;
  filters: any;
  refParam: any;
  type:string;
  keyParm:string
}

export default function Filter({view, filters,refParam,deleteFilter,arrayAux,handdleFilters,setView,type,keyParm }:FilterParams){
  const [exclude, setExclude] = useState<boolean>(false)
  const selectRef = useRef(null);
  const options = arrayAux.map((v) => {return {value: v, label: (exclude?'!':'')+v}})
  const resetFilter = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectRef?.current?.commonProps?.clearValue()
  }
  return(
    <div className={'filters-select'}>
      <div className={'filters-invert'} onClick={(v) => { resetFilter(); setExclude(!exclude)}}>
        <input readOnly type={"checkbox"} checked={exclude} />
        Invert
      </div>
      <Select
        ref={selectRef}
        isClearable={true}
        isSearchable={true}
        options={options}
        placeholder={(exclude?'!':'') + type}
        onChange={(item) => handdleFilters(item ? item.value : '', keyParm, exclude)}
      />
    </div>
  );
}
