import { Link, useLocation } from 'react-router-dom'

export interface ILiElement{
    children: JSX.Element,
    name: string,
    configSize: string,
    url: string
}

export default function LiElement({children, name, configSize, url}: ILiElement):JSX.Element{
    const location = useLocation()
    return(
        <Link to={url}>
            <li className={`${(location.pathname === `/${url}`)? 'selected-li' : 'normal-li'} ${configSize === 'bigMain'? 'bigNavLeft-li': 'smallNavLeft-li'}`}>
                    {
                        configSize === 'bigMain'?
                        <>
                            <span>{name}</span>
                            <span className='children-li'>{children}</span>
                        </> :
                        <>
                            {children}
                        </>
                    }
            </li>
        </Link>
    )
}
