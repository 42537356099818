import { createSlice } from '@reduxjs/toolkit'
import getDateFormat from "../utils/getDateFormat"

function orderedList(list, value, parameter){
  let newList =list.sort((el1, el2) => {
      if((parameter === 'end_date'||parameter === 'start_date')){
          if(value){
              return getDateFormat(el1[parameter])?.toString().toLowerCase().localeCompare(getDateFormat(el2[parameter])?.toString().toLowerCase())
          }
          else{
              return getDateFormat(el2[parameter])?.toString().toLowerCase().localeCompare(getDateFormat(el1[parameter])?.toString().toLowerCase())
          }
      }
      else{
          if(value) {
              return el1[parameter]?.toString().toLowerCase().localeCompare(el2[parameter]?.toString().toLowerCase())
          }
          else{
              return el2[parameter]?.toString().toLowerCase().localeCompare(el1[parameter]?.toString().toLowerCase())
          }
      }
  })
  return newList
}

function sortList(filters, list) {
  let order = list.slice()

  const op = (f, v) => {
    return f.exclude? !v : v
  }

  if(filters?.status){
    const targetStatus = filters.status.filter.toLowerCase()
    order = order.filter((elem) => op(filters.status, elem.status.toLowerCase() === targetStatus))
  }

  if(filters?.client){
    const targetClient = filters.client.filter.toLowerCase()
    order = order.filter((elem) => op(filters.client, elem.client.toLowerCase() === targetClient))
  }

  if(filters?.aws_username){
    const targetUser = filters.aws_username.filter.toLowerCase() 
    order = order.filter((elem) => op(filters.aws_username, elem.aws_username.toLowerCase() === targetUser))
  }

  if(filters?.tags){
    order = order.filter((elem) => op(filters.tags, Object.keys(elem.tags).includes(filters.tags.filter)))
  }

  if(filters?.tasks){
    order = order.filter((elem) => op(filters.tasks, elem.tasks?.includes(filters.tasks.filter)))
  }

  if(filters?.order?.end_date){
    let value = true
    filters.order.end_date === 'growing'? value = true : value = false
    order = orderedList(order, value, "end_date")
  } else if(filters?.order?.start_date){
    let value = true
    filters.order.start_date === 'growing'? value = true : value = false
    order = orderedList(order, value, "start_date")
  } else if(filters?.order?.aws_username){
    let value = true;
    value = filters.order.aws_username === 'growing';
    order = orderedList(order, value, "aws_username")
  } else if(filters?.order?.client){
    let value = true;
    value = filters.order.client === 'growing';
    order = orderedList(order, value, "client")
  } else if(filters?.order?.status){
    let value = true;
    value = filters.order.status === 'growing';
    order = orderedList(order, value, "status");
  } else if(filters?.order?.fleet_execution_id){
    let value = true;
    value = filters.order.fleet_execution_id === 'growing';
    order = orderedList(order, value, "fleet_execution_id");
  }

  return order
}

export const executionsSlice = createSlice({
  name: "executions",
  initialState: {
    executions: null,
    executionsFilters: null,
    filters: { order: {start_date: "decreasing"} },
    viewCancelPopUp: false,
    dataTerminate: null
  },
  reducers: {
    addFleetExecutions: (state, action) => {
      state.executions = action.payload
      state.executionsFilters = action.payload;
    },
    addFilter: (state, action) => {
      const filters = action.payload
      state.filters = filters
      let list = state.executions
      const order = sortList(filters, list)
      state.executionsFilters = order
    },
    setViewTerminate: (state, action) => {
      state.viewCancelPopUp = action.payload.view
      state.dataTerminate = action.payload.data
    }
  },
});

export const { addFleetExecutions, addFilter, setViewTerminate } = executionsSlice.actions
export default executionsSlice.reducer
