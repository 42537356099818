import axios from 'axios';
import fetchAccessToken from '../utils/fetchAccessToken';

const getFleetEvents = async(fleet_execution_task_id:string) => {
   const token = await fetchAccessToken();

   return axios.get(`${process.env.REACT_APP_URL}/v2/user/fleet_events/${fleet_execution_task_id}`,{
      headers: {
         'Content-Type' : 'application/json',
         'Authorization': `Bearer ${token}`
      },
      timeout: 60000
   })
}

export default getFleetEvents;
