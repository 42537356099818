import { useState } from "react"

export interface IPopUpState{
    view: boolean,
    data: any
}

export default function usePopUp(){
    const [viewPopUp, setViewPopUp] = useState<IPopUpState>({
        view: false,
        data: null
    })

    const handdleViewPopUp = (value: boolean, data: any):void => {
        console.log({value,data});
        
        setViewPopUp({...viewPopUp, view: value, data: data})
    }

    return {viewPopUp, handdleViewPopUp}
}
