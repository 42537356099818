import useTableRowsFleetExecutionTask from '../../hooks/useTableRowsFleetExecutionTask'
import LayersIcon from '../icons/layers_icon'
import TagsIcon from '../icons/tags_icon'

export interface IJobSize{
  codename: string,
  run_type: string,
  memory: any,
  cpu: any
}

function JobSize({codename, run_type, memory, cpu}: IJobSize) {
  return (
    <>
      <div className='job-size'>
        <div>
        <span>Mem</span> <span>{memory<1000? memory+' MiB': Math.round(memory / 1000)+' GiB'}</span>
        </div><div>
        <span>CPU</span> <span>{cpu}</span>
        </div>
      </div>
    </>
  )
}


export interface IJobSizeProps{
  codename: string,
  jobSizes: any,
  handleEdit: any
}

export default function TableRows({codename, jobSizes, handleEdit}: IJobSizeProps){
  const cols = ['model_run', 'stacking', 'backtesting', 'dashboard_build']

  return(
    <>
      <div className='tbody'><p>{codename}</p></div>
      {cols.map((run_type) => {
        const job = jobSizes[run_type]
        return (
          <div className='tbody run-type' key={codename+run_type} onClick={(e) => handleEdit(e, run_type)}>
          {
            job && (
              <JobSize
                codename={codename}
                run_type={run_type}
                memory={job.memory}
                cpu={job.cpu}
              />
            )
          }
          </div>
        )
      })}
    </>
  )
}
