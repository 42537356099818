import { createSlice } from '@reduxjs/toolkit'

export const weeklyRefreshSlice = createSlice({
  name: "weeklyRefresh",
  initialState: {
    fleets: {},
    dashboards: {},
    events: {},
    schedule: {},
    executionTaskFilters: [],
    filters: { order: {fleet_execution_task_id: "growing"} },
    dataTerminate: null
  },
  reducers: {
    addFleet: (state, action) => {
      state.fleets = {...state.fleets, ...action.payload}
      state.executionTaskFilters = action.payload
    },
    addDashboard: (state, action) => {
      state.dashboards = {...state.dashboards, ...action.payload}
    },
    addEvent: (state, action) => {
      state.events = {...state.events, ...action.payload}
    },
    addIngestSchedule: (state, action) => {
      state.schedule = action.payload
    },
    addFilter: (state, action) => {
      const filters = action.payload
      state.filters = filters
    },
    setViewTerminate: (state, action) => {
      state.viewCancelPopUp = action.payload.view
      state.dataTerminate = action.payload.data
    }
  },
});

export const { addFleet, addDashboard, addEvent, addFilter, addIngestSchedule, setViewTerminate } = weeklyRefreshSlice.actions
export default weeklyRefreshSlice.reducer
