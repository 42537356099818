import ExecutionFilter from "./Filter/ExecutionFilter";

export default function FilterFleetExecutions(){

    return(
        <div className='filters-jobs'>
            <ExecutionFilter objKey='aws_username' name='User' />
            <ExecutionFilter objKey='client' name='Client' />
            <ExecutionFilter objKey='status' name='Status' />
            <ExecutionFilter objKey='tags' name='Tags' />
            <ExecutionFilter objKey='tasks' name='Tasks' />
        </div>
    )
}
