//Types
import { IconType } from "./inconTypes";

//Component
const DeployIcon = ({ width, height, fill }: IconType): JSX.Element => {
  return (
    <svg width={width} height={height} fill={fill} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="layer1">
            <path d="M 0 1 L 0 20 L 12 20 L 12 16 L 11 16 L 11 19 L 1 19 L 1 2 L 11 2 L 11 5 L 12 5 L 12 1 L 0 1 z M 15 7 L 18 10 L 5 10 L 5 11 L 18 11 L 15 14 L 16.5 14 L 20 10.5 L 16.5 7 L 15 7 z "/>
        </g>
    </svg>
  );
};

//Export
export default DeployIcon;
