//Types
import {IconType} from './inconTypes'

//Component
const NetworkIcon = ({width, height, fill}: IconType):JSX.Element => {
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 1000 800">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><path d="M867.5,621.4c67.7,0,122.5-54.8,122.5-122.5c0-67.6-54.8-122.5-122.5-122.5c-55.8,0-102.9,37.4-117.6,88.5H443.3l171.7-297.2h135c14.8,51.1,61.8,88.5,117.6,88.5c67.7,0,122.5-54.9,122.5-122.5C990,66,935.2,11.1,867.5,11.1c-55.8,0-102.9,37.4-117.6,88.5H575.6l-211,365.2H250.1c-14.8-51.1-61.8-88.5-117.6-88.5C64.8,376.4,10,431.2,10,498.9c0,67.7,54.8,122.5,122.5,122.5c55.8,0,102.9-37.4,117.6-88.5h114.5l211,367.5h174.3c14.8,51.1,61.8,88.5,117.6,88.5c67.7,0,122.5-54.8,122.5-122.5c0-67.6-54.8-122.5-122.5-122.5c-55.8,0-102.9,37.4-117.6,88.5H615L443.1,532.9h306.8C764.6,584,811.7,621.4,867.5,621.4L867.5,621.4z"/></g>
        </svg>
    )
}

//Export
export default NetworkIcon
