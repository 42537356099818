import React from "react";
import Depvar from "./Depvar";
import FleetExecutionTask from "../../TableFleetExecutions/TableFleetExecutionTask/FleetExecutionTask";
import CopyText from "../../Util/CopyText";

export interface IDeployProps {
  root_path: string;
  depvars: any;
  deployTask: any;
  dashboardsState: any;
  eventsState: any;
  handleViewPopUp:(value: boolean, data: any) => void;
}

export default function Deploy({ root_path, depvars, deployTask, dashboardsState, eventsState, handleViewPopUp}: IDeployProps) {

  if (deployTask.length < 1) {
    return (<div></div>)
  }

  const links = deployTask?.map((executionTask: any) => executionTask.links)
  const client = deployTask[0].machine_input.client
  const email = `send_client_email("${root_path}")`
  const revert = `as_client("${client}", recast::revert_deployment, c("required_but_unused"))`

  return (
    <div className="container-fleets">
      {deployTask.map((executionTask: any) => {
        return (
          <React.Fragment key={executionTask.fleet_execution_task_id}>
            <FleetExecutionTask
              expandRows={false}
              fleet_execution_id={executionTask.fleet_execution_id}
              executionTask={executionTask}
              handleViewPopUp={handleViewPopUp}
            />
          </React.Fragment>
        );
      })}

      <div className="ingestContainer">
        <div className="ingestCard">
          <h3>Copy R Code</h3>
          <CopyText title={"Revert Deployment"} data={revert} />
          <CopyText title={"Email"} data={email} />
        </div>

      </div>
    </div>
  )
}
