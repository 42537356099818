//Types
import { IconType } from "./inconTypes";

//Component
const ModelRunIcon = ({ width, height, fill }: IconType): JSX.Element => {
  return (
    <svg width={width} height={height} fill={fill} viewBox="4 0 32 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g transform="rotate(270, 16, 16) scale(1.25)">
        <path d="M8.984 9.047v-3.016h19v13.953h-3.016l0.062 3.031h-3.078v2.953h-18.936v-13.968h2.969v-3.016l2.999 0.063zM26.953 7h-17v2.047l15.078-0.062-0.062 10.031h1.984v-12.016zM24 10.016h-17.047v1.984h15v9.984h2.047v-11.968zM3.984 12.969v12.031h17v-12.031h-17z" />
      </g>
    </svg>
  );
};

//Export
export default ModelRunIcon;
