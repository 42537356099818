import Particles from "react-tsparticles"

export default function ParticlesComp():JSX.Element{
    return(
        <Particles 
            id="tsparticles"
            options={{
                background: {
                    color: {
                    value: "#fff",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                    },
                    modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    },
                },
                particles: {
                    color: {
                    value: "#CBC8C7",
                    },
                    links: {
                    color: "#aaa",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                    },
                    collisions: {
                    enable: true,
                    },
                    move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 2,
                    straight: false,
                    },
                    number: {
                    density: {
                        enable: true,
                        area: 1200,
                    },
                    value: 17,
                    },
                    opacity: {
                    value: 0.5,
                    },
                    shape: {
                    type: "circle",
                    },
                    size: {
                    random: true,
                    value: 5,
                    },
                },
                detectRetina: true,
                }}
            />
    )
}