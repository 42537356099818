import BookmarkIcon from '../icons/bookmark_icon'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import LettersIcon from '../icons/letters_icon';
import getInitials from '../../utils/getInitials';


export default function LiProfile():JSX.Element{
    const user = useContext(UserContext).user;
    const fullName = user?.fullName;
    const letters = getInitials(fullName)

    return(
        <li className='li-profile'>
            <div className='img-profile'>
                <LettersIcon letters={letters} size='large'/>
            </div>
            <div className='user-inf'>
                <p>{fullName}</p>
                <p className='enterprise-name'>Recast</p>
            </div>
            <div>
                <BookmarkIcon width='20px' height='20px' fill='#1bcfb4'/>
            </div>
        </li>
    )
}
