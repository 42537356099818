import axios from 'axios';
import fetchAccessToken from '../utils/fetchAccessToken';

const setJobSizes = async(client: string, run_type: string, memory: number, cpu: number) => {
  const token = await fetchAccessToken();

  return axios.post(`${process.env.REACT_APP_URL}/v2/user/client_job_size`,
    {
      client: client,
      run_type: run_type,
      memory: memory,
      cpu: cpu
    }, {
      headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${token}`
      },
      timeout: 60000
    }
  )
}

export default setJobSizes;
