import ReactDOM from 'react-dom'

function Modal({children}:any){
    return(
        children
    )
}

export default function ModalPortal({children, onClose}:any){
    const elem = document.getElementById('modal-root')
    if(elem){
        return ReactDOM.createPortal(
            <Modal onClose={onClose}>
                {children}
            </Modal>,
            elem)}
    else{
        return null
    }
}
