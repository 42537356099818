import useTableRowsClientJobSize from "../../hooks/useTableRowsClientJobSize";
import TableRows, { IJobSize } from './TableRows'
import { useCallback, useEffect } from 'react'

export interface IClientJobSizeProps {
  codename: string,
  jobSizes: IJobSize[],
  handdleView: any
}

export default function ClientJobSize({codename, jobSizes, handdleView}: IClientJobSizeProps){
  const {handleEdit} = useTableRowsClientJobSize(codename, jobSizes, handdleView)
  return(
    <div className="job-element" key={codename+'jobsize'}>
      <div className="tableJobs header-client-job-size" >
        <TableRows
          codename={codename}
          jobSizes={jobSizes}
          handleEdit={handleEdit}
          />
      </div>
    </div>
  )
}
