import { useDispatch } from "react-redux";
import { setViewEdit } from "../redux/clientsSlice";
import { IJobSize } from "../components/JobSizes/TableRows";


export default function useTableRowsClientJobSize(
  codename: string,
  jobSizes: IJobSize[],
  handdleViewPopUp: any
) {
  const dispatch = useDispatch();
  const handleEdit = (e: React.MouseEvent, run_type: string = 'stacking') => {
    if(!handdleViewPopUp){
      return;
    }
    e.stopPropagation();
    dispatch(
      setViewEdit({
        view: true,
        data: {
          codename,
          run_type,
          jobSizes
        },
      })
    );
  };
  return { handleEdit };
}
