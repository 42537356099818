import LogoutIcon from '../icons/logout_icon'
import { signOut } from 'aws-amplify/auth';

export default function Logout(): JSX.Element {
    return (
        <button onClick={() => {signOut();}}>
            <span className='hidden'>Logout</span>
            <LogoutIcon width='24px' height='24px' />
        </button>
    );
}